import axios from 'lib/axios'

interface UpdateCompanyData {
  name?: string
  type_company_id?: number
  contact_name?: string
  contact_email?: string
  phone?: string
  operative_id?: number
  has_integration?: string
  integration_type?: number
  commercial_name?: string
  category_id?: number
  logo?: File
}

interface ApiResponse {
  status: string
  data?: any
  message?: string
}

/**
 * @description Service to edit a company with proper validation and file handling
 * @param data Company data to update
 * @param companyId Company ID to update
 */
const editCompany = async (data: UpdateCompanyData, companyId: number): Promise<ApiResponse> => {
  try {
    const formData = new FormData()

    // Add all fields to formData only if they are defined
    if (data.name) formData.append('name', data.name)
    if (data.type_company_id) formData.append('type_company_id', data.type_company_id.toString())
    if (data.contact_name) formData.append('contact_name', data.contact_name)
    if (data.contact_email) formData.append('contact_email', data.contact_email)
    if (data.phone) formData.append('phone', data.phone)
    if (data.operative_id) formData.append('operative_id', data.operative_id.toString())

    // Siempre enviamos has_integration ya que es un booleano
    formData.append('has_integration', data.has_integration || 'false')

    // Solo enviamos integration_type si has_integration es true
    if (data.has_integration === 'true' && data.integration_type) {
      formData.append('integration_type', data.integration_type.toString())
    }

    if (data.commercial_name) formData.append('commercial_name', data.commercial_name)
    if (data.category_id) formData.append('category_id', data.category_id.toString())

    // Handle logo file if provided
    if (data.logo) {
      // Validate file type
      const validMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']
      if (!validMimeTypes.includes(data.logo.type)) {
        throw new Error('Tipo de archivo inválido. Solo se permiten JPG, PNG y GIF.')
      }
      formData.append('logo', data.logo)
    }

    console.log('FormData a enviar:', Object.fromEntries(formData.entries())) // Para debug

    const response = await axios.put(`/company/${companyId}`, formData, {
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  } catch (error: any) {
    console.error('Error updating company:', error)
    return {
      status: 'error',
      message: error.response?.data?.message || error.message || 'Error al actualizar la compañía',
    }
  }
}

export default editCompany
