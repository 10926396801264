
import { Container } from './styled'
import { Content, ContainerImage, ContainerForm } from './styled'

const AutLayout = ({children}:any): JSX.Element  => {

  return (
    <Container>
      <Content>
        <ContainerForm>
          {children}
        </ContainerForm>
        <ContainerImage />
      </Content>
    </Container>
  )
}

export default AutLayout
