import axios from 'lib/axios'

export interface BodyProps {
  modelId: number
  skillAssociations: AssociationsProps[]
}

export interface AssociationsProps {
  companySkillId: number
  skills3dIds: number[]
}

export const associateSkillsService = async (body: BodyProps) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/skills/associate`
  try {
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
