import axios from 'lib/axios'

export const deleteModel = async (modelId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/models/delete/${modelId}`
  try {
    const response = await axios.put(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
