import styled, { keyframes, css } from 'styled-components'

import React from 'react'
import { IOS_DEVICE } from 'constants/global.constants'

const iOS = IOS_DEVICE.test(navigator.userAgent)
interface PropsContainer {
  isOpen: boolean
  disabled?: boolean
  children?: React.ReactNode
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

export const Welcome = styled.div<PropsContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px;
  opacity: 0;
  text-align: center;
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${fadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards
        `
      : 'none'};
`

export const ResponsiveHeaderContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: -webkit-fill-available;
    height: 40px;
    padding: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
`
export const ResponsiveHeader = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }
`
export const SideBar = styled.div<PropsContainer>`
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  color: black;
  min-width: ${(props) => (props.isOpen ? '220px' : '75px')};
  width: ${(props) => (props.isOpen ? 'auto' : '75px')};
  height: 100vh;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 992px) {
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    z-index: 998;
    animation: ${(props) =>
      props.isOpen
        ? css`
            ${slideIn} 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards
          `
        : 'none'};
  }

  ${iOS &&
  css`
    position: fixed;
    height: 100dvh;
  `}
`
export const SidebarHeader = styled.div`
  display: flex;
  align-items: flex-start;
  height: 67px;
  width: 100%;
  padding-left: 20px;
  padding-top: 19px;
`
export const SidebarHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  left: 20px;
  justify-content: space-between;
  width: 85%;
`
export const HeaderContentItem = styled.svg`
  cursor: pointer;
`
export const HeaderContentItemCollapseIcon = styled.svg<PropsContainer>`
  @media (max-width: 992px) {
    margin-left: ${(props) => (props.isOpen ? '160px' : '0px')};
    cursor: pointer;
  }
`

export const HeaderContentItemLogo = styled.svg`
  @media (max-width: 992px) {
    margin-right: 20px;
  }
`
export const HeaderContentItemBluesiteLogo = styled.svg<PropsContainer>`
  @media (max-width: 992px) {
    display: ${(props) => (props.isOpen ? 'none' : 'none')};
  }
`

export const CollapseIcon = styled.div<PropsContainer>`
  transform: ${(props) => (props.isOpen ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
`

export const RightSectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  column-gap: 14px;
  height: 30px;
`

export const SidebarContent = styled.div`
  height: calc(100% - 67px);
  overflow: auto;
  color: black;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2d749c;
    border-radius: 10px;
    border: 1px solid #2d749c;
    cursor: pointer;
  }
`

export const sidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`
export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin-top: 30px;
`
export const LinkItem = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  font-weight: normal;
  gap: 10px;
  cursor: pointer;
  :hover {
    background: rgba(230, 171, 203, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 7px solid #c6016b;
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
`
export const LinkItemTwo = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  font-weight: normal;
  gap: 10px;
  cursor: pointer;
  :hover {
    background: rgba(230, 171, 203, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 7px solid #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
  &.disabled {
    cursor: default;
    background: none;
    box-shadow: none;
    border-left: none;
    color: #999;
    font-weight: normal;
    fill: #999;
  }
`

export const OptionItem = styled.div`
  height: 30px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  font-weight: normal;
  cursor: pointer;
`
export const LinkLogOut = styled.a<PropsContainer>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  margin-left: 12px;
  text-align: justify;
  font-size: 14px;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
`

export const Divider = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`
export const LogOut = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  bottom: 11px;
  color: red;
  cursor: pointer;
  margin-bottom: 20px;
`
export const LinkIcon = styled.svg<PropsContainer>`
  position: ${(props) => (props.isOpen ? 'static' : 'absolute')};
  width: 24px;
  height: 24px;

  :hover {
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
`
export const LinkIconTwo = styled.svg<PropsContainer>`
  position: ${(props) => (props.isOpen ? 'static' : 'absolute')};
  width: 24px;
  height: 24px;
  color: ${(props) => (props.disabled ? '#999' : 'initial')};

  :hover {
    color: ${(props) => (props.disabled ? '#999' : '#c6016b')};
    fill: ${(props) => (props.disabled ? '#999' : '#c6016b')};
    font-weight: ${(props) => (props.disabled ? 'normal' : 'bold')};
    pointer-events: none;
  }
`

export const TeamDevelopmentIcon = styled.div<{ url?: string }>`
  mask: url('${({ url }) => url}') no-repeat 0px 0px / cover;
  mask-size: 100% 100%;
  background-color: #1f2124;
  height: 24px;
  width: 24px;
  &:hover {
    background-color: #c6016b;
  }
  .active & {
    background-color: #c6016b;
  }
`

export const LinkItemThree = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  font-weight: normal;
  gap: 10px;
  cursor: pointer;
  :hover {
    background: rgba(230, 171, 203, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 7px solid #c6016b;
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
    margin-right: -7px;
    & ${TeamDevelopmentIcon} {
      background-color: #c6016b;
    }
  }
`

export const LinkIconThree = styled.div<PropsContainer>`
  position: ${(props) => (props.isOpen ? 'static' : 'absolute')};
  width: 24px;
  height: 24px;

  :hover {
    & ${TeamDevelopmentIcon} {
      background-color: #c6016b;
    }
  }
`

export const LinkLogOutIcon = styled.svg<PropsContainer>`
  position: ${(props) => (props.isOpen ? 'static' : 'absolute')};
  height: 24px;
  width: 24px;
  fill: red;
`
export const Anchor = styled.div<PropsContainer>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  text-align: left;
  font-size: 14px;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: 132px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
`
const StyledAnchor = styled.div<PropsContainer>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  text-align: left;
  font-size: 14px;
  transition: width 0.5s ease-in-out;
  width: 132px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
`

export const AnchorFunction: React.FunctionComponent<PropsContainer> = ({ isOpen, disabled, children }) => {
  return React.createElement(StyledAnchor, { isOpen, disabled }, children)
}

export const SubContent = styled.div<PropsContainer>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: row;
  margin-left: 0;
  width: 200px;
  overflow: hidden;
`
export const LinkSubContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -10px;
`
export const Line = styled.hr<PropsContainer>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  margin-left: 30px;
  border: 2px solid #cbcbcb;
  background: #cbcbcb;
  border-radius: 19px;
`
export const LinkItemSubContent = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-weight: normal;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    background: rgba(230, 171, 203, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 7px solid #c6016b;
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
`

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  background-color: #60cc1e;
  color: #ffffff;
  height: 16px;
  border-radius: 20px;
  padding: 6px 10px;
  margin-left: -10px;
`
