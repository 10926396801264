import { FC, useEffect, useState } from 'react'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { getModels } from './services/getModels'
import { useLocation } from 'react-router-dom'
import { BackButton, Button } from 'views/components/UI/Buttons'
import {
  ButtonContainer,
  Card,
  CardContainer,
  CardDescriptionSection,
  CardTitle,
  Container,
  ContentContainer,
  Head,
  ModalButtonsContainer,
  ModalTitle,
  Title,
} from './styled'
import { MainLayout } from 'views/layouts'
import AddIcon from '@mui/icons-material/Add'
import Modal from 'views/components/UI/Modal'
import Input from 'views/components/UI/TextInput'
import { createModel } from './services/createModel'
import { useNotification } from 'lib/context/notification.context'
import CircularProgress from '@mui/material/CircularProgress'
import SkillsCreation from './components/skills-creation/skillsCreation'
import SetSkillsLevel from './components/set-skills-level/setSkillsLevel'
import AssociateSkills from './components/associate-skills/associateSkills'

export interface ModelsProps {
  id: number
  name: string
  createdAt: string
}

const HomologationSkills: FC = () => {
  const location = useLocation()
  const { getError } = useNotification()
  const companyId = location.state.companyId
  const companyName = location.state.companyName
  const storagedStep = localStorage.getItem('formStep')
  const formActive = localStorage.getItem('formActive')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [isCreatingModel, setIsCreatingModel] = useState<boolean>(false)
  const [modelForm, setModelForm] = useState<boolean>(!!formActive || false)
  const [formStep, setFormStep] = useState<number>(Number(storagedStep) || 1)
  const [modelInfo, setModelInfo] = useState<ModelsProps>({} as ModelsProps)
  const [models, setModels] = useState<ModelsProps[]>([])
  const [refresh, setRefresh] = useState<boolean>(false)

  useEffect(() => {
    if (companyId) {
      setIsLoading(true)
      getModels(companyId)
        .then((res) => {
          if (res.status === 'success') {
            setModels(res.data)
          } else if (res.status === 'no content') {
            setOpenModal(true)
          }
          setIsLoading(false)
        })
        .catch(() => {
          getError('Ha ocurrido un error al intentar obtener los modelos de homologación')
          setIsLoading(false)
        })
    }
  }, [companyId, getError, refresh])

  const handleCreateModel = () => {
    if (inputValue) {
      setIsCreatingModel(true)
      createModel(inputValue.trim())
        .then((res) => {
          if (res.status === 'success') {
            setModelInfo(res.data)
            setOpenModal(false)
            setInputValue('')
            setIsCreatingModel(false)
            setModelForm(true)
            localStorage.setItem('modelInfo', JSON.stringify(res.data))
            localStorage.setItem('formActive', 'true')
          } else {
            getError('Ha ocurrido un error al intentar crear el modelo de homologación')
            setIsCreatingModel(false)
          }
        })
        .catch(() => {
          getError('Ha ocurrido un error al intentar crear el modelo de homologación')
          setIsCreatingModel(false)
        })
    }
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)
    return date
      .toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '/')
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const renderStep = () => {
    switch (formStep) {
      case 1:
        return (
          <SkillsCreation
            companyId={companyId}
            modelData={modelInfo}
            setModelInfo={setModelInfo}
            setFormStep={setFormStep}
            setModelForm={setModelForm}
          />
        )
      case 2:
        return (
          <SetSkillsLevel
            companyId={companyId}
            companyName={companyName}
            modelData={modelInfo}
            setModelInfo={setModelInfo}
            setFormStep={setFormStep}
            setModelForm={setModelForm}
          />
        )
      case 3:
        return (
          <AssociateSkills
            companyId={companyId}
            companyName={companyName}
            modelData={modelInfo}
            setModelInfo={setModelInfo}
            setFormStep={setFormStep}
            setModelForm={setModelForm}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        )

      default:
        return null
    }
  }

  if (isLoading) return <ModalLoader isLoading={isLoading} />

  return (
    <MainLayout>
      <Container>
        <Modal show={openModal} setShow={setOpenModal} hideCloseButton={true} onlyContent={false}>
          <ModalTitle>Crear nuevo modelo de homologación</ModalTitle>
          <Input
            type="text"
            label="Nombre del modelo"
            value={inputValue}
            onChange={handleInputChange}
            style={{ width: '70%', alignSelf: 'center', marginTop: '20px' }}
          />
          <ModalButtonsContainer>
            <BackButton onClick={handleCloseModal} text="Cancelar" hideIcon />
            <Button
              onClick={handleCreateModel}
              type="button"
              style={{ width: '140px' }}
              disabled={isCreatingModel || !inputValue.trim()}
            >
              {isCreatingModel ? <CircularProgress color="inherit" size="26px" /> : 'Crear'}
            </Button>
          </ModalButtonsContainer>
        </Modal>
        {!modelForm ? (
          <ContentContainer>
            <Head>
              <Title>Modelos</Title>
            </Head>
            <CardContainer>
              {models.length > 0 &&
                models.map((model, index) => (
                  <Card key={`list-models-${index}`}>
                    <CardTitle>{model.name}</CardTitle>
                    <CardDescriptionSection>{formatDate(model.createdAt)}</CardDescriptionSection>
                  </Card>
                ))}
            </CardContainer>
            <ButtonContainer>
              <Button
                onClick={handleOpenModal}
                type="button"
                style={{ display: 'flex', alignItems: 'center', padding: '34px' }}
              >
                Crear nuevo modelo
                <AddIcon fontSize="large" sx={{ marginLeft: '10px' }} />
              </Button>
            </ButtonContainer>
          </ContentContainer>
        ) : (
          renderStep()
        )}
      </Container>
    </MainLayout>
  )
}

export default HomologationSkills
