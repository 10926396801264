import { FC, useEffect, useState } from 'react'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { BackButton, Button } from 'views/components/UI/Buttons'
import {
  BottomContainer,
  Card,
  CardContainer,
  CardTitle,
  Container,
  Description,
  Head,
  ModalButtonsContainer,
  ModalTitle,
  SubTitle,
  Title,
  ContentContainer,
  ColumnsContainer,
  ThreeDSkillsContainer,
  ThreeDCard,
  ChipContainer,
} from './styled'
import Modal from 'views/components/UI/Modal'
import { useNotification } from 'lib/context/notification.context'
import CircularProgress from '@mui/material/CircularProgress'
import { getCompanySkills } from '../../services/getCompanySkills'
import { deleteModel } from '../../services/deleteModel'
import { ModelsProps } from '../../homologationSkills'
import getAllSkills from 'services/common/skills/getByFilters'
import SearchBar from 'views/components/Searchbar'
import Radio from '@mui/material/Radio'
import Chip from '@mui/material/Chip'
import { associateSkillsService } from '../../services/associateSkillsService'
import { usePreventNavigation } from '../../hooks/usePreventNavigation'

interface Props {
  companyId: number
  modelData: ModelsProps
  companyName: string
  refresh: boolean
  setFormStep: (step: number) => void
  setModelInfo: (info: ModelsProps) => void
  setModelForm: (form: boolean) => void
  setRefresh: (refresh: boolean) => void
}

interface SkillsProps {
  id: number
  name: string
  companyId: number
}

interface ThreeDSkillsProps {
  id: number
  skill: string
}

interface SkillAssociationProps {
  id: number
  name: string
  threeDSkills: ThreeDSkillsProps[]
}

const AssociateSkills: FC<Props> = ({
  companyId,
  modelData,
  setFormStep,
  setModelInfo,
  setModelForm,
  companyName,
  setRefresh,
  refresh,
}) => {
  const localModel = JSON.parse(localStorage.getItem('modelInfo') || '{}')
  const levelSelected = localStorage.getItem('levelSelected')
  const levelName = localStorage.getItem('levelName')
  const { getError, getSuccess, getWarning } = useNotification()
  const [modelId] = useState<number>(modelData?.id || localModel.id)
  const [modelName] = useState<string>(modelData?.name || localModel.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [isDeletingModel, setIsDeletingModel] = useState<boolean>(false)
  const [skillsCreated, setSkillsCreated] = useState<SkillsProps[]>([])
  const [threeDSkills, setThreeDSkills] = useState<ThreeDSkillsProps[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleCleanup = async () => {
    if (modelId) {
      const res = await deleteModel(modelId)
      if (res.status === 'success') {
        setOpenConfirmModal(false)
        setIsDeletingModel(false)
        setModelInfo({} as ModelsProps)
        localStorage.removeItem('formStep')
        localStorage.removeItem('formActive')
        localStorage.removeItem('levelSelected')
        localStorage.removeItem('levelName')
        setFormStep(1)
        setModelForm(false)
        setRefresh(!refresh)
      }
    }
  }

  const { isModalOpen, handleClose, handleConfirm } = usePreventNavigation({
    shouldPrevent: true,
    onNavigationAttempt: handleCleanup,
  })

  // MARK: SKILLS ASSOCIATIONS

  const [skillAssociations, setSkillAssociations] = useState<SkillAssociationProps[]>([])
  const [selectedSkillId, setSelectedSkillId] = useState(0)

  const handleSkillSelect = (skillId: number) => {
    setSelectedSkillId(Number(skillId))
  }

  const toggle3DSkill = (threeDSkill: ThreeDSkillsProps) => {
    if (selectedSkillId === 0) {
      getWarning('Debes seleccionar una competencia para poder relacionar una 3D Skill')
      return
    }
    setSkillAssociations((prevAssociations) => {
      return prevAssociations.map((skill) => {
        if (skill.id === selectedSkillId) {
          const isAlreadySelected = skill.threeDSkills.some((s) => s.id === threeDSkill.id)

          if (isAlreadySelected) {
            return {
              ...skill,
              threeDSkills: skill.threeDSkills.filter((s) => s.id !== threeDSkill.id),
            }
          } else if (skill.threeDSkills.length < 3) {
            return {
              ...skill,
              threeDSkills: [...skill.threeDSkills, threeDSkill],
            }
          } else {
            getWarning('Ups! Ya relacionaste el máximo de 3D Skills, continúa con la siguiente competencia')
          }
        }
        return skill
      })
    })
  }

  const handleChipDelete = (skillId: number, threeDSkill: ThreeDSkillsProps) => {
    setSkillAssociations((prevAssociations) => {
      return prevAssociations.map((skill) => {
        if (skill.id === skillId) {
          return {
            ...skill,
            threeDSkills: skill.threeDSkills.filter((s) => s.id !== threeDSkill.id),
          }
        }
        return skill
      })
    })
  }

  // MARK: SERVICES

  useEffect(() => {
    setIsLoading(true)
    getCompanySkills(companyId, modelId)
      .then((res) => {
        if (res.status === 'success') {
          setSkillsCreated(res.data.companySkills)
          setSkillAssociations(
            res.data.companySkills.map((skill: SkillsProps) => ({
              id: skill.id,
              name: skill.name,
              threeDSkills: [],
            }))
          )
        }
        setIsLoading(false)
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener las competencias')
        setIsLoading(false)
      })
  }, [companyId, getError, modelId])

  useEffect(() => {
    const body = {
      levelId: Number(levelSelected),
    }
    getAllSkills(body, 1, 100, searchTerm)
      .then((res) => {
        if (res.status === 'OK') {
          setThreeDSkills(res.skills)
        } else if (res.status === 'error') {
          setThreeDSkills([])
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener las competencias')
      })
  }, [getError, levelSelected, searchTerm])

  const handleDeleteModel = () => {
    setIsDeletingModel(true)
    handleConfirm()
    deleteModel(modelId)
      .then((res) => {
        if (res.status === 'success') {
          setOpenConfirmModal(false)
          setIsDeletingModel(false)
          setModelInfo({} as ModelsProps)
          localStorage.removeItem('formStep')
          localStorage.removeItem('formActive')
          localStorage.removeItem('levelSelected')
          localStorage.removeItem('levelName')
          setFormStep(1)
          setModelForm(false)
          setRefresh(!refresh)
        } else {
          getError('Ha ocurrido un error al intentar eliminar el modelo de homologación')
          setIsDeletingModel(false)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar eliminar el modelo de homologación')
        setIsDeletingModel(false)
      })
  }

  // MARK: HANDLERS

  const handleSave = () => {
    setIsLoading(true)

    const body = {
      modelId,
      skillAssociations: skillAssociations.map((association) => ({
        companySkillId: association.id,
        skills3dIds: association.threeDSkills.map((s) => s.id),
      })),
    }

    associateSkillsService(body)
      .then((res) => {
        if (res.status === 'success') {
          getSuccess('Se guardó el modelo correctamente')
          setFormStep(1)
          setModelForm(false)
          setModelInfo({} as ModelsProps)
          localStorage.removeItem('formStep')
          localStorage.removeItem('formActive')
          localStorage.removeItem('levelSelected')
          localStorage.removeItem('levelName')
          localStorage.removeItem('modelInfo')
          setIsLoading(false)
          setRefresh(!refresh)
        } else {
          setIsLoading(false)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar asociar las competencias')
        setIsLoading(false)
      })
  }

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
    handleClose()
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const areAllSkillsAssociated = () => {
    return skillsCreated.every((skill) => {
      const association = skillAssociations.find((a) => a.id === skill.id)
      return association && association.threeDSkills.length > 0
    })
  }

  if (isLoading) return <ModalLoader isLoading={isLoading} />

  // MARK: RENDER

  return (
    <Container>
      <ContentContainer>
        <Modal
          show={openConfirmModal || isModalOpen}
          setShow={setOpenConfirmModal}
          hideCloseButton={true}
          onlyContent={false}
        >
          <ModalTitle style={{ fontSize: '24px' }}>Si confirmas, perderás todo el progreso</ModalTitle>
          <ModalButtonsContainer style={{ justifyContent: 'space-evenly' }}>
            <BackButton onClick={handleCloseConfirmModal} text="Cancelar" hideIcon />
            <Button onClick={handleDeleteModel} type="button" style={{ width: '140px' }} disabled={isDeletingModel}>
              {isDeletingModel ? <CircularProgress color="inherit" size="26px" /> : 'Confirmar'}
            </Button>
          </ModalButtonsContainer>
        </Modal>
        <Head>
          <Title>{modelName}</Title>
        </Head>
        <Description style={{ marginBottom: '20px' }}>Nivel: {levelName}</Description>

        <ColumnsContainer>
          <CardContainer>
            <SubTitle style={{ fontSize: '20px' }}>Competencias {companyName}</SubTitle>
            {skillsCreated.length > 0 &&
              skillsCreated.map((skill, index) => {
                const associatedSkills = skillAssociations.find((s) => s.id === skill.id)?.threeDSkills || []
                return (
                  <Card
                    key={`list-skills-${index}`}
                    onClick={() => handleSkillSelect(skill.id)}
                    selected={skill.id === selectedSkillId}
                  >
                    <CardTitle>{skill.name}</CardTitle>
                    <ChipContainer associatedSkills={!!associatedSkills.length}>
                      {associatedSkills.map((threeDSkill) => (
                        <Chip
                          key={threeDSkill.id}
                          label={threeDSkill.skill}
                          onDelete={(e) => {
                            e.stopPropagation()
                            handleChipDelete(skill.id, threeDSkill)
                          }}
                          size="small"
                          sx={{
                            backgroundColor: '#ffffff',
                            border: '1px solid #00AB55',
                            color: '#00AB55',
                            '& .MuiChip-label': {
                              fontSize: '12px',
                            },
                            '& .MuiChip-deleteIcon': {
                              color: '#00aa54',
                              '&:hover': {
                                color: '#007b3b',
                              },
                            },
                          }}
                        />
                      ))}
                    </ChipContainer>
                  </Card>
                )
              })}
          </CardContainer>
          <ThreeDSkillsContainer>
            <SubTitle style={{ fontSize: '20px' }}>3D Skills</SubTitle>
            <SearchBar
              style={{ marginBottom: '20px', maxWidth: '100%' }}
              label="Buscar"
              value={searchTerm}
              onChange={handleChangeSearch}
            />
            {threeDSkills.length > 0 &&
              threeDSkills.map((skill, index) => {
                const currentSkill = skillAssociations.find((s) => s.id === selectedSkillId)
                const isSelected = currentSkill?.threeDSkills.some((s) => s.id === skill.id)
                const canSelect = (currentSkill?.threeDSkills && currentSkill?.threeDSkills?.length < 3) || isSelected

                return (
                  <ThreeDCard key={`list-3dskills-${index}`} onClick={() => toggle3DSkill(skill)}>
                    <CardTitle>{skill.skill}</CardTitle>
                    <Radio
                      checked={isSelected || false}
                      disabled={!canSelect && !isSelected}
                      sx={{
                        '&.Mui-checked': {
                          color: '#00aa54',
                        },
                      }}
                    />
                  </ThreeDCard>
                )
              })}
          </ThreeDSkillsContainer>
        </ColumnsContainer>
      </ContentContainer>
      <BottomContainer>
        <BackButton onClick={handleOpenConfirmModal} text="Cancelar" hideIcon />
        <Button onClick={handleSave} disabled={!areAllSkillsAssociated() || isLoading} type="button">
          Guardar
        </Button>
      </BottomContainer>
    </Container>
  )
}

export default AssociateSkills
