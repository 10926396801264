/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import Stepper from 'views/components/Stepper'
import { MainLayout } from 'views/layouts'
import { Container, Row } from './styled'
import { H2, P } from 'views/components/UI/Text'
import { Button, BackButton } from 'views/components/UI/Buttons'
import CardTypeTest from 'views/components/Cards/SelectTest'
import brainImage from 'assets/images/brain-icon.png'
import boxImage from 'assets/images/box-icon.png'
import { platformAdminRoutes } from 'router/routes'
import { STEPS_NEW_PROCESS } from '../constants'
import {
  stepThreeSelector,
  setStepThreeTrigger,
  stepTwoSelector,
  stepOneSelector,
  stepFiveSelector,
  setUserListAdded,
} from 'ducks/createProcess'
import { useTranslation } from 'react-i18next'
import { useGetCreditsAvailables } from 'hooks/inventory/useInventory'
import { User } from '../Users/constants'
import useDeleteUsersInProcess from 'hooks/process/useDeleteUsersInProcess/use-delete-users-in-process'
import { deleteModelProcessAssociation } from 'views/screens/super-admin/homologation-skills/services/deleteModelProcessAssociation'

type TypeTests = 'betesa' | 'skills'

const SelectTestsType = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const stepOneData = useSelector(stepOneSelector)
  const stepTwoData = useSelector(stepTwoSelector)
  const stepThreeData = useSelector(stepThreeSelector)
  const stepFiveData = useSelector(stepFiveSelector)
  const InventoryTypeIlimit = 3
  const InventoryTypeIlimitIntegration = 4
  const OPERATIVE_LEVEL = 4
  const TACTICAL_LEVEL = 3
  const STRATEGIC_LEVEL = 2
  const [testsSelected, setTestsSelected] = useState<TypeTests[]>([])

  const { refreshCredits } = useGetCreditsAvailables(stepOneData.inventoryTypeId)

  const { deleteUsersInProcess, deleteTestsInProcess } = useDeleteUsersInProcess()

  useEffect(() => {
    if (stepFiveData?.userListAdded) {
      dispatch(setUserListAdded([]))

      const idUsersToDelete = stepFiveData?.userListAdded
        .map((user: User) => user.id)
        .filter((id: number) => id !== undefined)

      if (idUsersToDelete.length > 0) {
        const deleteUsers = async () => {
          await deleteUsersInProcess(stepOneData.processId, idUsersToDelete)
        }
        deleteUsers()
      }
    }
    const deleteModelProcess = async () => {
      await deleteModelProcessAssociation(stepOneData.processId)
    }
    const deleteTests = async () => {
      await deleteTestsInProcess(stepOneData.processId)
    }
    setTimeout(() => {
      deleteTests()
      deleteModelProcess()
    }, 500)

    refreshCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectTest = (test: TypeTests) => {
    if (_.includes(testsSelected, test)) {
      setTestsSelected(_.without(testsSelected, test))
    } else {
      setTestsSelected((prevTestsSelected) => [...prevTestsSelected, test])
    }
  }

  const handleNextStep = () => {
    const dataStepThreeToSave = {
      processId: stepTwoData?.processId,
      onlineCourse: false,
      useBetesaTest: testsSelected.includes('betesa'),
      useSkillsTest: testsSelected.includes('skills'),
    }

    try {
      dispatch(setStepThreeTrigger.run(dataStepThreeToSave))
    } catch (error) {
      return error
    } finally {
      if (testsSelected.includes('betesa')) {
        navigate(platformAdminRoutes.HomologationSkills)
        return
      }

      if (testsSelected.includes('skills') && !testsSelected.includes('betesa')) {
        navigate(platformAdminRoutes.ProcessSkills)
        return
      }
    }
  }

  useEffect(() => {
    if (Object.keys(stepThreeData).length > 0) {
      const listTestsSelected: TypeTests[] = []
      if (stepThreeData?.useBetesaTest) {
        listTestsSelected.push('betesa')
      }
      if (stepThreeData?.useSkillsTest) {
        listTestsSelected.push('skills')
      }
      setTestsSelected(listTestsSelected)
    }
  }, [stepThreeData])

  const hasValidInventoryType = (inventoryType: number) => {
    const validTypes = [InventoryTypeIlimit, InventoryTypeIlimitIntegration]
    return validTypes.includes(inventoryType)
  }

  const isOperativeLevel = (levelName: string, level: number, inventoryType: number) => {
    return (levelName === 'Operativo' || level === OPERATIVE_LEVEL) && hasValidInventoryType(inventoryType)
  }

  const isTacticalLevel = (levelName: string, level: number) => {
    return levelName === 'Táctico' || level === TACTICAL_LEVEL
  }

  const isStrategicLevel = (levelName: string, level: number) => {
    return levelName === 'Estratégico' || level === STRATEGIC_LEVEL
  }

  const shouldShowBetesaTest = () => {
    return (
      isOperativeLevel(stepOneData.levelName, stepOneData.level, stepOneData.inventoryType) ||
      isTacticalLevel(stepOneData.levelName, stepOneData.level) ||
      isStrategicLevel(stepOneData.levelName, stepOneData.level)
    )
  }

  return (
    <MainLayout>
      <Container>
        <H2 marginBottom="30px">Configuración de pruebas</H2>
        <Stepper activeStep={2} steps={STEPS_NEW_PROCESS} />
        <P margin="30px 0px 20px 6px">Seleccione pruebas para aplicar a los participantes</P>
        <Row>
          {shouldShowBetesaTest() && (
            <CardTypeTest
              title="Betesa - Talentos Naturales"
              image={brainImage}
              onClick={() => handleSelectTest('betesa')}
              checked={testsSelected && (testsSelected.includes('betesa') || false)}
            />
          )}
          <CardTypeTest
            title={t('global.common.skillText')}
            image={boxImage}
            onClick={() => handleSelectTest('skills')}
            checked={testsSelected && (testsSelected.includes('skills') || false)}
          />
        </Row>
        <Row style={{ flex: 1 }} justifyContent="space-between" alignItems="flex-end">
          <BackButton onClick={() => navigate(platformAdminRoutes.PreconfigProcess)} />
          <Button disabled={testsSelected.length < 1} type="submit" onClick={handleNextStep}>
            {t('global.common.nextButtonText')}
          </Button>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default SelectTestsType
