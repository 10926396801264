import { FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Logo from '../../components/UI/Logo'
import { H2, P } from '../../components/UI/Text'
import Separator from 'views/components/UI/Separator/Index'
import RegisterForm from '../../components/Forms/Register'
import activateUserService from 'services/common/users/activateUser/index'
import findUser from 'services/findUser'
import { useNotification } from 'lib/context/notification.context'
import { FIRST_TOKEN } from 'constants/global.constants'
import { useDispatch } from 'react-redux'
import Size from 'views/components/assets/size.svg'
import { ErrorContainer, ErrorIcon } from './styled'
import { logout } from 'ducks/user/reducer'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  padding-top: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 1024px) {
    max-width: 600px;
  }
`
interface IDIV {
  width?: string
  height?: string
  direction?: string
  justify?: string
  align?: string
  bgColor?: string
  padding?: string
  margin?: string
  border?: string
  borderRadius?: string
  boxShadow?: string
  position?: string
  top?: string
  left?: string
  right?: string
  bottom?: string
}

const Div = styled.div<IDIV>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ''};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  background-color: ${(props) => props.bgColor || 'transparent'};
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '0'};
  border-radius: ${(props) => props.borderRadius || '0'};
  border: ${(props) => props.border || '0'};
  box-shadow: ${(props) => props.boxShadow || '0'};
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top || '0'};
  left: ${(props) => props.left || '0'};
  right: ${(props) => props.right || '0'};
  bottom: ${(props) => props.bottom || '0'};
`

interface RegisterFormValues {
  document: string
  birthDate: string
  password: string
  documentType: string
  firstName?: string
  lastName?: string
  role?: string
}

const RegisterFormContainer: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getWarning, getSuccess } = useNotification()

  const [tokenActivation, setTokenActivation] = useState<string>('')
  const [nameUser, setNameUser] = useState<string>('')
  const [lastNameUser, setLastNameUser] = useState<string>('')
  const [personId, setPersonId] = useState<number>(0)
  const [isLoading, setLoading] = useState(true)
  const [userRole, setUserRole] = useState<string>('')
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false)
  const [error, setError] = useState(false)
  const searchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(searchParams)
  const token: string = params.t
  localStorage.setItem(FIRST_TOKEN, token)

  const handleSetUserData = useCallback((response: any) => {
    if (response.data) {
      setNameUser(response.data.first_name)
      setLastNameUser(response.data.last_name)
      setUserRole(response.data.role)
      setPersonId(response.data.personId)
      setIsAdminUser(['platform_admin', 'super_admin', 'super_platform_admin'].includes(response.data.role ?? ''))
      return;
    }

    navigate('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogOut = () => {
    dispatch(logout())
    navigate('/login')
    localStorage.clear()
  }

  useEffect(() => {
    setTimeout(() => {
      setTokenActivation(token)
      if (Object.keys(params).length > 0) {
        if (nameUser === '' && lastNameUser === '') {
          findUser()
            .then((res) => {
              if (res.data.active) {
                getWarning(t('global.notifications.userActiveNoRegister'))
                handleLogOut()
                setLoading(false)
                return
              }
              handleSetUserData(res)
              setLoading(false)
            })
            .catch(() => {
              setLoading(false)
              setError(true)
            })
        }
      } else {
        setLoading(false)
      }
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, token, nameUser, lastNameUser, handleSetUserData, dispatch])

  const handleRegister = async (values: RegisterFormValues) => {
    try {
      const { document, birthDate, password, documentType } = values

      const response = await activateUserService({
        token: tokenActivation,
        document,
        birthDate,
        password,
        documentType: parseInt(documentType),
      })

      if (response?.status === 'OK' || response?.status === 'success') {
        getSuccess(t('global.notifications.createAccountSuccess'))
        setTimeout(() => {
          navigate('/login')
        }, 2000)
      } else {
        getWarning(t('global.notifications.createAccountError'))
      }
    } catch (err: any) {
      console.error('Error activating user:', err)
      getWarning(t('global.notifications.createAccountError'))
    }
  }

  return (
    <Container>
      <Logo width="65%" />
      <Separator height="1rem" />
      <H2 fontSize="32px" margin="10px 0px">
        {t('global.common.welcome')}
      </H2>
      {!error ? (
        <Div width="80%" padding="0px 15px 0px 15px" margin="10px 0px 20px 0px">
          <P>{isAdminUser ? t('global.register.beforeLoginText') : t('global.register.beforeLoginText2')}{t('global.register.beforeLoginText3')}</P>
        </Div>
      ) : null}
      {error ? (
        <ErrorContainer>
          <ErrorIcon src={Size} alt="Not found icon" />
          <P fontSize="20px">Ha ocurrido un error, por favor intenta más tarde.</P>
        </ErrorContainer>
      ) : isLoading ? (
        <P> Cargando datos del usuario... </P>
      ) : (
        <RegisterForm
          token={token}
          personId={personId}
          defaultValues={{
            firstName: nameUser,
            lastName: lastNameUser,
            role: userRole,
          }}
          onSubmit={(e: any) => handleRegister(e)}
        />
      )}
    </Container>
  )
}

export default RegisterFormContainer
