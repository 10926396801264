import axios from 'lib/axios'

export const getAllLevels = async () => {
  const url = `${process.env.REACT_APP_MAIN_API}/level/all`
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
