import { Typography } from '@mui/material'
import styled from 'styled-components'
interface TitleProps {
  margin?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafcff;
  min-height: 100vh;
  overflow: auto;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3.5% 3.5% 0 3.5%;
`

export const Title = styled(Typography).attrs({
  variant: 'h2',
})<TitleProps>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.00735em;
    color: #303030;
    margin: ${(props) => props.margin};

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 540px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

export const SubTitle = styled(Typography).attrs({
  variant: 'h3',
})<TitleProps>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.00735em;
    color: #303030;
    margin: ${(props) => props.margin};

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 36px;
    }

    @media (max-width: 540px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
`

export const Description = styled(Typography).attrs({
  variant: 'h6',
})`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #303030;
`

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 20px;
`

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 50px;
  width: 100%;
  max-width: 100%;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eeeffa;
  gap: 20px;
  border-radius: 5px;
  padding: 30px;
  flex: 1;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 320px);
  overflow-y: auto;
`

export const ThreeDSkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #faeef5;
  gap: 20px;
  border-radius: 5px;
  padding: 30px;
  flex: 1;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 320px);
  overflow-y: auto;
`

export const Card = styled.div<{ selected?: boolean }>`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  // max-width: 520px;
  padding: 20px;
  border: ${(props) => (props.selected ? '1px solid #C6016B' : 'none')};
  cursor: pointer;

  @media (max-width: 1646px) {
    width: auto;
    align-self: stretch;
  }
`

export const ThreeDCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  // max-width: 520px;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 1646px) {
    width: auto;
    align-self: stretch;
  }
`

export const CardTitle = styled.span`
  font-weight: 400;
  color: #1f2124;
  font-size: 18px;
`

export const CardDescriptionSection = styled.span`
  margin-top: 10px;
  font-weight: 500;
  color: #a3a4a6;
  font-size: 14px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
`

export const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  padding: 10px 40px;
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
`

export const CancelButton = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  color: #a2a2a2;
  cursor: pointer;
`

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`

export const SelectContainer = styled.div`
  width: '180px';
`

export const ChipContainer = styled.div<{ associatedSkills: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: ${(props) => (props.associatedSkills ? '20px' : '0')};
`
