import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from 'views/screens/Platform-administrator/Home'
import NotFound from 'views/screens/NotFound'
import IdealProfileBetesa from 'views/screens/Platform-administrator/Process/Create/IdealProfileBetesa'
import PlatformSkills from 'views/screens/Platform-administrator/Skills'
import Analytics from 'views/screens/Platform-administrator/Analytics'
import Inventory from 'views/screens/inventory'
import MyProcesses from 'views/screens/Platform-administrator/Process/MyProcess'
import DeliveredProcesses from 'views/screens/Platform-administrator/Process/DetailProcessDelivered/detail-process-delivered'
import UsersWithIncompletedTests from 'views/screens/Platform-administrator/Process/Create/Users/UsersIncompletedTests'
import UnpublishProcess from 'views/screens/Platform-administrator/Process/DraftProcesses'
import DeliveredProcess from 'views/screens/Platform-administrator/Process/Delivered'
import EvaluatedSpace from 'views/screens/Platform-administrator/EvaluatedSpace'
import EvaluatedDetail from 'views/screens/Platform-administrator/EvaluatedDetail'
import ProfileView from 'views/screens/Platform-administrator/Profile'
import CreateUsers from 'views/screens/Platform-administrator/Process/Create/Users'
import CreateUsersNewProcess from 'views/screens/Platform-administrator/Process/Create/Users/Create'
import ResumeUsersProcess from 'views/screens/Platform-administrator/Process/Resume/users-resume-to-send-test'
import UpdateEmail from 'views/screens/Platform-administrator/Process/UpdateEmail'
import CreateProcess from 'views/screens/Platform-administrator/Process/Create'
import SelectPreconfig from 'views/screens/Platform-administrator/Process/Create/SelectPreconfigProcess'
import SelectTestsType from 'views/screens/Platform-administrator/Process/Create/SelectTestsType'
import HomologationSkills from 'views/screens/Platform-administrator/Process/Create/HomologationSkills'
import HomologationCreateEditSkills from 'views/screens/Platform-administrator/Process/Create/HomologationSkills/HomologationCreateEditSkills'
import ProcessSkills from 'views/screens/Platform-administrator/Process/Create/Skills'
import TalentsToDevelop from 'views/screens/Platform-administrator/TalentsToDevelop'
import SelectTalentsToDevelop from 'views/screens/Platform-administrator/SelectTalentsToDevelop'
import SelectSkillsToDevelop from 'views/screens/Platform-administrator/DevelopmentPlan/SelectSkillsToDevelop'
import NotUploaded from 'views/screens/Platform-administrator/Process/Create/Users/not-uploaded'
// routes
import { platformAdminRoutes } from 'router/routes'
import TestResultsBetesa from 'views/components/TestResultsBetesa'
import ResultsSkills from 'views/components/TestResultsSkills/results-skills'
import PreviewGenerateReport from 'views/components/preview-generate-report'
import WatchSettings from 'views/screens/Platform-administrator/Process/WatchSettings'
import TeamDevelopment from 'views/screens/Platform-administrator/TeamDevelopment'
import UsersManager from 'views/screens/Platform-administrator/TeamDevelopment/Components/UsersManager'
import TeamMapDevelopment from 'views/screens/Platform-administrator/TeamDevelopment/Components/TeamMapDevelopment'
import HistoryTeamMap from 'views/screens/Platform-administrator/TeamDevelopment/Components/HistoryTeamMap'
import DevelopmentPlan from 'views/screens/Evaluated-User/pages/DevelopmentPlan'
import EditIdealProfileBetesa from 'views/screens/Platform-administrator/Process/Edit/IdealProfileBetesa/ideal-profile-betesa'

const PlatformAdministratorRouter: FC = () => (
  <Routes>
    <Route path="/login" element={<Navigate to="/" replace />} />
    <Route path={platformAdminRoutes.Home} element={<Home />} />
    <Route path="*" element={<NotFound />} />
    <Route path={platformAdminRoutes.CreateProcess} element={<CreateProcess />} />
    <Route path={platformAdminRoutes.PreconfigProcess} element={<SelectPreconfig />} />
    <Route path={platformAdminRoutes.HomologationSkills} element={<HomologationSkills />} />
    <Route path={platformAdminRoutes.CreateEditHomologationSkills} element={<HomologationCreateEditSkills />} />
    <Route path={platformAdminRoutes.ProcessSkills} element={<ProcessSkills />} />
    <Route path={platformAdminRoutes.SelectTestsType} element={<SelectTestsType />} />
    <Route path={platformAdminRoutes.IdealProfileBetesa} element={<IdealProfileBetesa />} />
    <Route path={platformAdminRoutes.EditIdealProfileBetesa} element={<EditIdealProfileBetesa />} />
    <Route path={platformAdminRoutes.CreateUsers} element={<CreateUsers />} />
    <Route path={platformAdminRoutes.NewUser} element={<CreateUsersNewProcess />} />
    <Route path={platformAdminRoutes.Resume} element={<ResumeUsersProcess />} />
    <Route path={platformAdminRoutes.UpdateEmail} element={<UpdateEmail />} />
    <Route path={platformAdminRoutes.NotUpload} element={<NotUploaded />} />
    <Route path={platformAdminRoutes.UnpublishedProcess} element={<UnpublishProcess />} />
    <Route path={platformAdminRoutes.ProcessesCreated} element={<DeliveredProcess />} />
    <Route path={platformAdminRoutes.MyProcess} element={<MyProcesses />} />
    <Route path={platformAdminRoutes.DetailProcessDelivered} element={<DeliveredProcesses />} />
    <Route path={platformAdminRoutes.UsersWithIncompleteTests} element={<UsersWithIncompletedTests />} />
    <Route path={platformAdminRoutes.EvaluatedUsers} element={<EvaluatedSpace />} />
    <Route path={platformAdminRoutes.DetailEvaluatedUser} element={<EvaluatedDetail />} />
    <Route path={platformAdminRoutes.TestResultSkills} element={<ResultsSkills />} />
    <Route path={platformAdminRoutes.TestResultBetesa} element={<TestResultsBetesa />} />
    <Route path={platformAdminRoutes.Administrators} element={<ProfileView />} />
    <Route path={platformAdminRoutes.Skills} element={<PlatformSkills />} />
    <Route path={platformAdminRoutes.Analitycs} element={<Analytics />} />
    <Route path={platformAdminRoutes.TalentsToDevelop} element={<TalentsToDevelop />} />
    <Route path={platformAdminRoutes.SelectTalentsToDevelop} element={<SelectTalentsToDevelop />} />
    <Route path={platformAdminRoutes.SelectSkillsToDevelop} element={<SelectSkillsToDevelop />} />
    <Route path={platformAdminRoutes.Inventory} element={<Inventory />} />
    <Route path="/reports-download" element={<PreviewGenerateReport />} />
    <Route path={platformAdminRoutes.watchSettings} element={<WatchSettings />} />
    <Route path={platformAdminRoutes.TeamDevelopment} element={<TeamDevelopment />} />
    <Route path={platformAdminRoutes.TeamDevelopmentUsers} element={<UsersManager />} />
    <Route path={platformAdminRoutes.TeamMapDevelopment} element={<TeamMapDevelopment />} />
    <Route path={platformAdminRoutes.TeamDevelopmentHistory} element={<HistoryTeamMap />} />
    <Route path={platformAdminRoutes.DevelopmentPlan} element={<DevelopmentPlan />} />
  </Routes>
)

export default PlatformAdministratorRouter
