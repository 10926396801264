import axios from 'lib/axios'

export const getModels = async (companyId: number, level?: number) => {
  let url = `${process.env.REACT_APP_MAIN_API}/companies/models/${companyId}`

  if (level !== undefined) {
    url += `?level=${level}`
  }

  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
