import { FC, useEffect, useState } from 'react'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { BackButton, Button } from 'views/components/UI/Buttons'
import {
  BottomContainer,
  Card,
  CardContainer,
  CardTitle,
  Container,
  Description,
  Head,
  ModalButtonsContainer,
  ModalTitle,
  SelectContainer,
  SubTitle,
  Title,
  ContentContainer,
} from './styled'
import Modal from 'views/components/UI/Modal'
import { useNotification } from 'lib/context/notification.context'
import CircularProgress from '@mui/material/CircularProgress'
import { getCompanySkills } from '../../services/getCompanySkills'
import { deleteModel } from '../../services/deleteModel'
import { ModelsProps } from '../../homologationSkills'
import Select from 'views/components/UI/Select'
import { getAllLevels } from '../../services/getAllLevels'
import { usePreventNavigation } from '../../hooks/usePreventNavigation'

interface Props {
  companyId: number
  modelData: ModelsProps
  companyName: string
  setFormStep: (step: number) => void
  setModelInfo: (info: ModelsProps) => void
  setModelForm: (form: boolean) => void
}

interface SkillsProps {
  id: number
  name: string
  companyId: number
}

const SetSkillsLevel: FC<Props> = ({ companyId, modelData, setFormStep, setModelInfo, setModelForm, companyName }) => {
  const localModel = JSON.parse(localStorage.getItem('modelInfo') || '{}')
  const { getError } = useNotification()
  const [modelId] = useState<number>(modelData?.id || localModel.id)
  const [modelName] = useState<string>(modelData?.name || localModel.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [isDeletingModel, setIsDeletingModel] = useState<boolean>(false)
  const [skillsCreated, setSkillsCreated] = useState<SkillsProps[]>([])
  const [levels, setLevels] = useState<any[]>([])
  const [selectedLevel, setSelectedLevel] = useState<string>('')

  const handleCleanup = async () => {
    if (modelId) {
      const res = await deleteModel(modelId)
      if (res.status === 'success') {
        setOpenConfirmModal(false)
        setIsDeletingModel(false)
        setModelInfo({} as ModelsProps)
        localStorage.removeItem('formStep')
        localStorage.removeItem('formActive')
        setFormStep(1)
        setModelForm(false)
      }
    }
  }

  const { isModalOpen, handleClose, handleConfirm } = usePreventNavigation({
    shouldPrevent: true,
    onNavigationAttempt: handleCleanup,
  })

  // MARK: SERVICES

  useEffect(() => {
    setIsLoading(true)
    getCompanySkills(companyId, modelId)
      .then((res) => {
        if (res.status === 'success') {
          setSkillsCreated(res.data.companySkills)
        }
        setIsLoading(false)
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener las competencias')
        setIsLoading(false)
      })
  }, [companyId, getError, modelId])

  useEffect(() => {
    getAllLevels()
      .then((res) => {
        if (res.status === 'OK') {
          setLevels(res.levels)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener los niveles')
      })
  }, [getError])

  const handleDeleteModel = () => {
    setIsDeletingModel(true)
    handleConfirm()
    deleteModel(modelId)
      .then((res) => {
        if (res.status === 'success') {
          setOpenConfirmModal(false)
          setIsDeletingModel(false)
          setModelInfo({} as ModelsProps)
          localStorage.removeItem('formStep')
          localStorage.removeItem('formActive')
          setFormStep(1)
          setModelForm(false)
        } else {
          getError('Ha ocurrido un error al intentar eliminar el modelo de homologación')
          setIsDeletingModel(false)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar eliminar el modelo de homologación')
        setIsDeletingModel(false)
      })
  }

  // MARK: HANDLERS

  const handleNextStep = () => {
    localStorage.setItem('levelSelected', selectedLevel)
    localStorage.setItem('levelName', levels.find((level) => level.id === selectedLevel)?.name)
    setFormStep(3)
    localStorage.setItem('formStep', '3')
  }

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
    handleClose()
  }

  const handleLevelChange = (event: any) => {
    setSelectedLevel(event)
  }

  const optionLevels = levels.map((level) => {
    return {
      value: level.id,
      label: level.name,
    }
  })

  if (isLoading) return <ModalLoader isLoading={isLoading} />

  // MARK: RENDER

  return (
    <Container>
      <ContentContainer>
        <Modal
          show={openConfirmModal || isModalOpen}
          setShow={setOpenConfirmModal}
          hideCloseButton={true}
          onlyContent={false}
        >
          <ModalTitle style={{ fontSize: '24px' }}>Si confirmas, perderás todo el progreso</ModalTitle>
          <ModalButtonsContainer style={{ justifyContent: 'space-evenly' }}>
            <BackButton onClick={handleCloseConfirmModal} text="Cancelar" hideIcon />
            <Button onClick={handleDeleteModel} type="button" style={{ width: '140px' }} disabled={isDeletingModel}>
              {isDeletingModel ? <CircularProgress color="inherit" size="26px" /> : 'Confirmar'}
            </Button>
          </ModalButtonsContainer>
        </Modal>
        <Head>
          <Title>{modelName}</Title>
        </Head>
        <Description style={{ marginBottom: '20px' }}>Elige el nivel asociado a las competencias</Description>
        <SelectContainer style={{ width: '380px', margin: '20px 0 50px 0' }}>
          <Select
            options={optionLevels}
            value={selectedLevel}
            onChange={handleLevelChange}
            label="Nivel"
            hideNeverChoice={true}
          />
        </SelectContainer>
        <SubTitle>Competencias - {companyName}</SubTitle>
        <CardContainer>
          {skillsCreated.length > 0 &&
            skillsCreated.map((skill, index) => (
              <Card key={`list-skills-${index}`}>
                <CardTitle>{skill.name}</CardTitle>
              </Card>
            ))}
        </CardContainer>
      </ContentContainer>
      <BottomContainer>
        <BackButton onClick={handleOpenConfirmModal} text="Cancelar" hideIcon />
        <Button onClick={handleNextStep} disabled={!selectedLevel} type="button">
          Siguiente
        </Button>
      </BottomContainer>
    </Container>
  )
}

export default SetSkillsLevel
