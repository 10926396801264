import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperInfoItem = styled.div`
  display: flex;
  width: 240px;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
`

export const NameProcessWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`

export const WrapperItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 14px;
  padding-left: 48px;
`

export const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`

export const InfoText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #797a7c;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 95px;
`

export const AskOptionText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`

export const ButtonBack = styled.div`
  color: #2d749c;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c6016b;
  color: white;
  font-weight: bold;
  border: none;
  padding: 14px 22px;
  border-radius: 8px;
  font-size: 16px;
`

export const TopContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
`

export const HomologationButtonContainer = styled.div`
  display: flex;
`
