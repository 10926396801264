import styled from "styled-components";


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

// forgot password

export const ForgotPassword = styled.a`
  color: #C6016B;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`
// error text

export const ErrorText = styled.p`
    color: red;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    margin-top: 10px;
`
