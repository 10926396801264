export const superAdminRoutes = {
  Home: '/',
  InfoEvaluatedUser: '/super-admin/info-evaluated-user/',
  ListBetesaUninterpreted: '/interpretation',
  InterpretationBetesa: '/interpretation/detail/',
  ReinterpretationBetesa: '/reinterpretation/detail',
  PlatformAdminsByCompany: '/company/admin-platform-per-company',
  HomologationSkills: '/company/homologation-skills',
  Companies: '/companies',
  CompanyInfo: '/company',
  CreateCompany: '/companies/new/',
  UpdateCompany: '/companies/update/',
  Percentiles: '/percentiles/',
  EvaluatedUserByCompany: '/company/evaluated-per-company/',
  InfoEvaluatedUserByCompany: '/company/evaluated-per-company/detail/',
  CreateGeneralText: '/modules/texts/create',
  // Percentiles
  PercentilsSkills: '/modules/skills/percentils',
  UpdatePercentilsSkills: '/modules/skills/percentils/update',
  // Test results
  TestResult: '/test-result',
  ResultsBetesa: '/company/evaluated-per-company/detail/user-test-detail-talents',
  ResultsSkills: '/company/evaluated-per-company/detail/user-test-detail-skills',
  //Questionnaire
  UpdateSkillQuestion: '/modules/skills/questions/update',
  SettingQuestionaire: '/modules/talents/questionnaire/settings/',
  EditBasicInfoQuestionaire: '/modules/talents/questionnaire/settings/info',
  QuestionsQuestionaire: '/modules/talents/questionnaire/settings/questions/',
  UpdateQuestionQuestionaire: '/modules/talents/questionnaire/questions/update',
  CreateQuestionQuestionaire: '/modules/talents/questionnaire/questions/create/',
  QuestionsTalentQuestionaire: '/modules/talents/questionnaire/questions',
  ChaptersQuestionaire: '/modules/talents/questionnaire/chapter',
  CreateChapterQuestionaire: '/modules/talents/questionnaire/chapter/create/',
  EditChapter: '/modules/talents/questionnaire/chapter/edit/',
  // Users
  Users: '/users',
  UsersByCompany: '/users/company',
  SuspendUser: '/users-suspend-request',
  // Inventory
  Inventory: '/inventory',
  NewInventory: '/inventory/new',
  UpdateInventory: '/inventory/update',
  // Role family
  RoleFamily: '/role-family',
  ListBetesaInterpretedByUser: '/interpretation/list-betesa-by-user',
}

export const platformAdminRoutes = {
  Home: '/',
  CreateProcess: '/create-process',
  PreconfigProcess: '/create-process/pre-config',
  SelectTestsType: '/create-process/select-tests-type',
  HomologationSkills: '/create-process/skills-homologation',
  CreateEditHomologationSkills: '/create-process/skills-homologation/create-edit',
  ProcessSkills: '/create-process/skills',
  CreateUsers: '/create-process/create-users',
  NewUser: '/create-process/new-user',
  Resume: '/create-process/resume',
  UpdateEmail: '/create-process/update-email',
  NotUpload: '/create-process/not-upload',
  IdealProfile: '/create-process/select-profile',
  UnpublishedProcess: '/unpublish-process',
  ProcessesCreated: '/processes-created',
  DetailProcessDelivered: '/processes-created/detail',
  UsersWithIncompleteTests: '/users-with-incomplete-tests',
  MyProcess: '/my-process',
  EvaluatedUsers: '/space-evaluated-users',
  DetailEvaluatedUser: '/evaluated-user-detail',
  TestResultSkills: '/user-test-detail-skills',
  TestResultBetesa: '/user-test-detail-talents',
  DownloadResults: '/download-results',
  Administrators: '/administrators',
  ProfileUpdate: '/profile/update',
  Inventory: '/inventory',
  Skills: '/skills',
  Analitycs: '/analytics',
  // Team development
  TeamDevelopment: '/team-development',
  TeamDevelopmentUsers: '/team-development/users',
  TeamMapDevelopment: '/team-development/map',
  TeamDevelopmentHistory: '/team-development/history',
  // Development plan
  TalentsToDevelop: '/user/talents-to-develop',
  SelectTalentsToDevelop: '/user/talents-to-develop/select',
  DevelopmentPlan: '/development-plan',
  SelectSkillsToDevelop: '/user/skills-to-develop/select',
  // tests
  ProcessSelectTest: '/create-process/select-test',
  // Ideal profile
  IdealProfileBetesa: '/create-process/ideal-profile-betesa',
  EditIdealProfileBetesa: '/edit/ideal-profile-betesa/',
  watchSettings: '/processes-created/detail/watch-settings',
}

export const evaluatedUsersRoutes = {
  Home: '/',
}

export const specialAdminRoutes = {
  Modules: '/modules/',
  // Betesa modules
  ModulesBetesa: '/modules/betesa/',
  ModulesTalents: '/modules/talents/',
  QuestionnairesBetesa: '/modules/talents/questionnaire/',
  NewQuestionnaireBetesa: '/modules/talents/questionnaire/new/',
  BetesaTexts: '/modules/talents/texts',
  BetesaGeneralTexts: '/modules/talents/texts/generals',
  AlertBetesaTexts: '/modules/talents/texts/alert-level',
  QuadrantBetesaTexts: '/modules/talents/texts/quadrant',
  // Skills modules
  ModulesSkills: '/modules/skills',
  SkillsQuestions: '/modules/skills/questions',
  NewSkillsQuestion: '/modules/skills/questions/new',
  UpdateSkillsQuestion: '/modules/skills/questions/update',
  SkillsActions: '/modules/skills/actions',
  NewActionSkill: '/modules/skills/actions/new-action',
  NewSkillsAction: '/modules/skills/actions/new',
  UpdateSkillsAction: '/modules/skills/actions/update',
  UpdateSkillsOneAction: '/modules/skills/actions/update/action',
  SkillTexts: '/modules/skills/texts',
  BetesaActions: '/modules/talents/actions',
  NewBetesaTalent: '/modules/talents/new',
  EditBetesaTalent: '/modules/talents/edit',
  NewBetesaAction: '/modules/talents/actions/new',
  UpdateBetesaAction: '/modules/talents/actions/update',
  UpdateBetesaOneAction: '/modules/talents/actions/update/action',
  NewSkill: 'modules/skills/new',
  UpdateSkill: 'modules/skills/update',
  SkillBank: 'modules/skills/bank',
  //Texts
  TextsResultsSkills: '/modules/skills/texts/acording-to-result',
  TextNewResultSkills: '/modules/skills/texts/acording-to-result/new',
  QualitativeTextsSkills: '/modules/skills/texts/qualitative',
  GeneralTextsSkills: '/modules/skills/texts/general',
  ListBetesaInterpretedByUser: '/interpretation/list-betesa-by-user',
}
