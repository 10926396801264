import axios from 'lib/axios'

export interface BodyProps {
  name: string
  skillId: number
}

export const editSkills = async (body: BodyProps) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/edit-skills`
  try {
    const response = await axios.put(url, body)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
