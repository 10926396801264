import { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import MainLayout from 'views/layouts/Main'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import ModalSkills from './modal-skills'
import {
  ActionAddSkillCreateProcess,
  ActionDeleteSkillCreateProcess,
} from 'views/components/UI/BluesiteTable/Actions/GridActions'
import {
  Container,
  StepsContainer,
  NextButtonContainer,
  Row,
  SelectContainer,
  ButtonContainer,
  Line,
  LineContainer,
} from './styled'
import { H2, H4, H5, SubtitleBold } from 'views/components/UI/Text'
import MinimumRecord from './MinimumScore/skill-minimun-score'
import Steps from 'views/components/Stepper'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { Button, BackButton } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'
import Select from 'views/components/UI/Select'
import { STEPS_NEW_PROCESS } from '../constants'
import { platformAdminRoutes } from 'router/routes'
import { rootProcessSelector, setStepFourTrigger, stepThreeSelector, stepFourSelector } from 'ducks/createProcess'
import { generalDataSelector } from 'ducks/auth'
import useGetSkills from 'hooks/skills/useGetSkills/useGetSkills'
import { BETESA_CATEGORY_ID } from 'constants/global.constants'
import getSkillsByFilters from 'services/common/skills/getByFilters'
import { ModelsProps, Skill, SkillWithMinRecord } from 'types/common'
import { HEADERS, CompetencesIdealProfile } from './constants'
import { Pagination, DEFAULT_LIMIT } from 'constants/global.constants'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { getModels } from 'views/screens/super-admin/homologation-skills/services/getModels'
import { companyIdSelector } from 'ducks/user'
import boxImage from 'assets/images/box-icon.png'
import CardTypeTest from 'views/components/Cards/SelectTest'
import { getModelDetails } from 'views/screens/super-admin/homologation-skills/services/getModelDetails'
import { associateModelWithProcess } from 'views/screens/super-admin/homologation-skills/services/associateModelWithProcess'
import { getModelWithProcess } from 'views/screens/super-admin/homologation-skills/services/getModelWithProcess'

interface Filters {
  levelId: number
  groupId?: number
  name?: string
  page: number
}

interface ThreeDSkillsProps {
  id: number
  name: string
}

export interface ModelSkillsProps {
  id: number
  name: string
  skills3d: ThreeDSkillsProps[]
}

const MIN_WEIGHT = 0
const MIN_SKILLS = 3
const MAX_SKILLS = 12

function ProcessSkills() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { getSkills } = useGetSkills()
  const { getWarning, getError, getSuccess } = useNotification()
  const dataStepFour = useSelector(stepFourSelector)
  const companyId = useSelector(companyIdSelector)
  const storagedModelSkills = JSON.parse(localStorage.getItem('modelSkills') || '[]')
  const { process } = useSelector(rootProcessSelector)
  const stepThreeData = useSelector(stepThreeSelector)
  const { data: generalData } = useSelector(generalDataSelector)
  const groupOptions = useMemo(
    () =>
      generalData?.group
        ?.filter((group: any) => group.id !== BETESA_CATEGORY_ID)
        .map((group: any) => ({ label: group.name, value: group.id })) || [],
    [generalData]
  )
  const level: number = process.stepOne.level

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [pagination, setPagination] = useState<Pagination>({ totalPages: 1, totalResults: 0 })
  const [loading, setLoading] = useState<boolean>(false)
  const [skills, setSkills] = useState<Skill[]>([])
  const [skillsSelected, setSkillsSelected] = useState<any>([])
  const [skillsWithMinimumRecord, setSkillsWithMinimumRecord] = useState<SkillWithMinRecord[]>([])
  const [currentSkill, setCurrentSkill] = useState<any>(null)
  const [idSkillSelected, setIdSkillSelected] = useState<number[]>([])

  const [searchFilters, setSearchFilters] = useState<Filters>({ levelId: level, page: 1 })
  const [showModal, setShowModal] = useState(false)
  const [isEditedMinimumRecord, setIsEditingMinimumRecord] = useState<boolean>(false)
  const [loadingModels, setLoadingModels] = useState<boolean>(false)
  const [models, setModels] = useState<ModelsProps[]>([])
  const [showModelsSelector, setShowModelsSelector] = useState<boolean>(false)
  const [selectedModel, setSelectedModel] = useState<string>('')
  const [selected3DSkills, setSelected3DSkills] = useState<boolean>(false)
  const [modelSkills, setModelSkills] = useState<ModelSkillsProps[]>(storagedModelSkills || [])

  useEffect(() => {
    if (location.state?.back) {
      setIsEditingMinimumRecord(true)
    }
  }, [location.state?.back])

  useEffect(() => {
    if (level) {
      setLoadingModels(true)
      getModels(companyId, level)
        .then((response) => {
          if (response.status === 'success') {
            setModels(response.data)
            if (!location.state?.back) {
              setShowModelsSelector(true)
            }
          }
        })
        .catch(() => {
          getError('Error al obtener los modelos')
        })
        .finally(() => {
          setLoadingModels(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, level])

  useEffect(() => {
    const competencesIdealProfile: CompetencesIdealProfile[] = dataStepFour?.competencesIdealProfile || []
    if (competencesIdealProfile.length > 0) {
      setLoading(true)
      const skillsWithWeight = competencesIdealProfile.map(async (item) => {
        try {
          const skillItem: any = await getSkills({ id: item.skill_id })
          return {
            id: item.skill_id,
            skill_id: item.skill_id,
            skill: skillItem[0].skill,
            skill_name: skillItem[0].skill,
            weight: item.weight,
          }
        } catch (error) {
          return error
        }
      })

      Promise.all(skillsWithWeight).then((resolvedSkills) => {
        setSkillsSelected(resolvedSkills)
        setLoading(false)
      })
    }
  }, [dataStepFour, getSkills])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      const question = true
      getSkillsByFilters(searchFilters, searchFilters.page, DEFAULT_LIMIT, searchFilters.name, question)
        .then((response) => {
          setSkills(response.skills)
          setPagination({
            totalPages: response.total_pages,
            totalResults: response?.total_results || 0,
          })
        })
        .catch(() => {
          getError('Error al obtener las competencias')
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters, searchFilters.name, searchFilters.groupId, getError, searchFilters.page])

  // MARK: HANDLERS
  const handleAddSkill = useCallback(
    (skill: Skill) => {
      if (skillsSelected.length >= MAX_SKILLS) {
        return {}
      }
      setSkillsSelected([skill, ...skillsSelected])
      setIdSkillSelected([skill.id, ...idSkillSelected])
      getSuccess('Competencia agregada correctamente')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skillsSelected, idSkillSelected]
  )

  const onDeleteSkill = useCallback(
    (skill: { id: number | string }) => {
      if (skillsSelected.length < 1) {
        getWarning('No hay competencias seleccionadas, debes volver a seleccionarlos')
      }
      const newSkillsSelected = skillsSelected.filter((item: any) => item?.id !== skill.id)
      setSkillsSelected(newSkillsSelected)
    },
    [skillsSelected, getWarning]
  )

  const handleViewNextSkill = useCallback(() => {
    const index = skills.findIndex((item) => item.id === currentSkill.id)
    if (index === skills.length - 1) {
      setCurrentSkill(skills[0])
    } else {
      setCurrentSkill(skills[index + 1])
    }
  }, [skills, currentSkill])

  const handleViewPreviousSkill = useCallback(() => {
    const index = skills.findIndex((item) => item.id === currentSkill.id)
    if (index === 0) {
      setCurrentSkill(skills[skills.length - 1])
    } else {
      setCurrentSkill(skills[index - 1])
    }
  }, [skills, currentSkill])

  const handleSearch = useCallback(
    (key: string, value: any) => {
      setSearchFilters({ ...searchFilters, [key]: value })
    },
    [searchFilters]
  )

  const handlePagination = useCallback(
    (page: number) => {
      setPagination({ ...pagination })
      setSearchFilters({ ...searchFilters, page })
    },
    [pagination, searchFilters]
  )

  const handleDefineMinimumRecord = useCallback(
    (skillsToProcess: any[]) => {
      if (skillsToProcess.length < 1) {
        getWarning('No hay competencias seleccionadas, debes volver a seleccionarlos')
      }
      setIsEditingMinimumRecord(true)

      const skillsSelectedWithMinWeight = skillsToProcess.map((skill: any) => {
        return {
          skill_id: skill.id,
          weight: skill.weight || MIN_WEIGHT,
        }
      })

      const dataStepFourToSave: any = {
        processId: stepThreeData?.processId,
        competencesIdealProfile: skillsSelectedWithMinWeight,
      }
      if (dataStepFour?.betesaIdealProfile?.betesa_skills) {
        dataStepFourToSave.betesaIdealProfile = dataStepFour.betesaIdealProfile
      }
      try {
        if (!skillsSelectedWithMinWeight.some((skill: any) => skill.weight === 0 || skill.weight === undefined)) {
          dispatch(setStepFourTrigger.run(dataStepFourToSave))
        } else {
          if (!isFirstRender) {
            setIsFirstRender(false)
            getWarning('Debes asignar un puntaje mínimo a todas las competencias')
          }
        }
      } catch (error) {
        console.error('error saving the skills and ideal profile', error)
      }
    },
    [stepThreeData, dispatch, getWarning, dataStepFour.betesaIdealProfile, isFirstRender]
  )

  const hasInvalidSkills = () => {
    return skillsSelected.some((skill: any) => skill.weight <= MIN_WEIGHT || skill.weight === undefined)
  }

  const handleNextStepWithMinimumRecord = useCallback(() => {
    const skillsSelectedWithMinWeight = skillsSelected.map((skill: any) => {
      if (skill.weight <= 0) {
        getWarning(`La skill ${skill.skill} no tiene un weight válido. Por favor, añade un weight mayor a cero.`)
      }

      return {
        skill_id: skill.id,
        weight: skill.weight,
      }
    })

    if (skillsSelectedWithMinWeight.includes(undefined) || skillsSelectedWithMinWeight.includes({})) {
      return {}
    } else {
      if (hasInvalidSkills()) {
        getWarning(`Debes asignar un puntaje mínimo de ${MIN_WEIGHT + 1} a cada competencia`)
        return {}
      } else {
        if (skillsWithMinimumRecord.length > 0) {
          const dataStepFourToSave: any = {
            processId: stepThreeData?.processId,
            competencesIdealProfile: skillsSelectedWithMinWeight,
          }
          if (dataStepFour?.betesaIdealProfile?.betesa_skills) {
            dataStepFourToSave.betesaIdealProfile = dataStepFour.betesaIdealProfile
          }
          try {
            dispatch(setStepFourTrigger.run(dataStepFourToSave))
          } catch (error) {
            console.error('error saving the skills and ideal profile', error)
          } finally {
            navigate(platformAdminRoutes.CreateUsers)
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stepThreeData,
    dispatch,
    navigate,
    skillsWithMinimumRecord,
    dataStepFour.betesaIdealProfile,
    skillsSelected,
    getWarning,
  ])

  if (loadingModels) return <ModalLoader isLoading={loadingModels} />

  const modelOptions = models.map((model) => {
    return {
      value: model.id,
      label: model.name,
    }
  })

  const handleModelSelected = (modelId: string) => {
    setSelectedModel(modelId)
    setSelected3DSkills(false)
  }

  const handleSelect3DSkills = () => {
    setSelected3DSkills(!selected3DSkills)
    setSelectedModel('')
  }

  const handleNextWith3DSkills = () => {
    setShowModelsSelector(false)
    setModelSkills([])
  }

  const handleNextWithModel = async () => {
    if (selectedModel) {
      setLoading(true)
      try {
        const modelDetails = await getModelDetails(Number(selectedModel))
        if (modelDetails) {
          const skills3dIds = modelDetails.data.skills.flatMap((skill: ModelSkillsProps) =>
            skill.skills3d.map((s3d) => s3d.id)
          )

          const associate = await associateModelWithProcess(modelDetails.data.id, stepThreeData.processId)
          if (!associate) {
            getError('Error al asociar el modelo con el proceso')
          }

          const allSkills = await getSkillsByFilters({ levelId: level }, 1, 1000)

          const findSkills = new Set(allSkills.skills.filter((skill) => skills3dIds.includes(skill.id)))

          setSkillsSelected(Array.from(findSkills))
          setModelSkills(modelDetails.data.skills)
          localStorage.setItem('modelSkills', JSON.stringify(modelDetails.data.skills))

          handleDefineMinimumRecord(Array.from(findSkills))
          setShowModelsSelector(false)
          setLoading(false)
        } else {
          getError('Error al obtener los detalles del modelo')
          setLoading(false)
        }
      } catch (error) {
        getError('Error al obtener los detalles del modelo')
        setLoading(false)
      }
    }
  }

  const handleBackButton = async () => {
    if (isEditedMinimumRecord && !models.length) {
      setIsEditingMinimumRecord(false)
      return
    }

    if (isEditedMinimumRecord && models.length) {
      setLoading(true)
      const modelWithProcess = await getModelWithProcess(stepThreeData.processId)
      if (modelWithProcess.status === 'success') {
        if (modelSkills.length) {
          setShowModelsSelector(true)
          setSkillsSelected([])
          localStorage.removeItem('modelSkills')
        }
        setIsEditingMinimumRecord(false)
        setModelSkills([])
      } else {
        setIsEditingMinimumRecord(false)
      }
      setLoading(false)
      return
    }

    const nextRoute = stepThreeData.useBetesaTest
      ? platformAdminRoutes.IdealProfileBetesa
      : platformAdminRoutes.SelectTestsType

    if (showModelsSelector || !models.length) {
      navigate(nextRoute)
    } else {
      setShowModelsSelector(true)
    }
  }
  return (
    <MainLayout>
      {!models.length || !showModelsSelector ? (
        <Container>
          {!isEditedMinimumRecord ? (
            <>
              <H2 margin="30px 0px 24px 0px">{t('global.common.skillText')}</H2>
              <StepsContainer>
                <Steps steps={STEPS_NEW_PROCESS} activeStep={3} />
              </StepsContainer>
              <Row justify="space-between">
                <H4
                  style={{
                    width: '100%',
                  }}
                  margin="30px 0px 24px 0px"
                >
                  {t('global.common.skillText')}
                </H4>
                <Row justify="flex-end" margin="0px 0px -120px 0px">
                  <SubtitleBold style={{ margin: '0px 16px 0px 0px' }}>Grupos de competencias:</SubtitleBold>
                  <Select
                    width="220px"
                    label="Grupos"
                    value={searchFilters?.groupId || ''}
                    onChange={(e) => {
                      if (e && e !== '0') {
                        handleSearch('groupId', e)
                      } else {
                        const filtersWithoutGroup = { ...searchFilters }
                        delete filtersWithoutGroup.groupId
                        setSearchFilters(filtersWithoutGroup)
                      }
                    }}
                    options={groupOptions}
                  />
                </Row>
              </Row>
              <BluesiteTable
                data={skills}
                headers={HEADERS}
                searchBar={true}
                searchLabel="Buscar competencia:"
                onSearch={(e: any) => {
                  if (e !== '') {
                    handleSearch('name', e)
                  } else {
                    const filtersWithoutName = { ...searchFilters }
                    delete filtersWithoutName.name
                    setSearchFilters(filtersWithoutName)
                  }
                }}
                totalPages={pagination.totalPages}
                handlePagination={handlePagination}
                loading={false}
                ActionComponent={ActionAddSkillCreateProcess}
                currentPage={searchFilters.page}
                extraDataAction={{
                  handleAddSkill,
                  skillsSelected,
                  onViewSkill: (e: any) => {
                    setCurrentSkill(e)
                    setShowModal(true)
                  },
                }}
              />
              {skillsSelected.length > 0 && (
                <BluesiteTable
                  data={skillsSelected}
                  headers={HEADERS}
                  searchBar={false}
                  searchLabel="Buscar competencia:"
                  totalPages={0}
                  currentPage={1}
                  showNumbers
                  limit={DEFAULT_LIMIT}
                  loading={false}
                  ActionComponent={ActionDeleteSkillCreateProcess}
                  extraDataAction={{
                    onDeleteSkill,
                  }}
                />
              )}
            </>
          ) : !loading && skillsSelected.length ? (
            <MinimumRecord
              skillSelected={skillsSelected}
              model3dSkills={modelSkills}
              onChange={(e: any) => {
                setSkillsWithMinimumRecord(e)
                const skillsSelectedWithWeight = skillsSelected.map((skill: any) => {
                  const skillWithWeight = e.find((skillWithMinRecord: any) => skillWithMinRecord.skill_id === skill.id)
                  return {
                    ...skill,
                    weight: skillWithWeight.weight,
                  }
                })
                setSkillsSelected(skillsSelectedWithWeight)
              }}
            />
          ) : null}
          {
            <NextButtonContainer>
              <BackButton onClick={handleBackButton} />
              {skillsSelected.length > 0 && (
                <Button
                  onClick={
                    !isEditedMinimumRecord
                      ? () => handleDefineMinimumRecord(skillsSelected)
                      : () => handleNextStepWithMinimumRecord()
                  }
                  onDisabledClick={() => {
                    /* eslint-disable */
                    skillsSelected.length < 3
                      ? getWarning(`Debes seleccionar al menos ${MIN_SKILLS} competencias`)
                      : skillsSelected.length > MAX_SKILLS
                      ? getWarning(`No puedes seleccionar más de ${MAX_SKILLS} competencias`)
                      : _.some(skillsSelected, (skill) => skill.weight === undefined || skill.weight <= MIN_WEIGHT)
                      ? getWarning(`Debes asignar un puntaje mínimo de ${MIN_WEIGHT + 1} a cada competencia`)
                      : null
                  }}
                  type="submit"
                  disabled={
                    isEditedMinimumRecord
                      ? hasInvalidSkills() || skillsSelected.length < 1
                        ? true
                        : false
                      : skillsSelected.length < MIN_SKILLS
                      ? true
                      : false
                  }
                >
                  {isEditedMinimumRecord ? 'Agregar usuarios' : 'Definir puntaje mínimo'}
                </Button>
              )}
            </NextButtonContainer>
          }
          {currentSkill && showModal && (
            <ModalSkills
              data={currentSkill}
              onClose={() => setShowModal(false)}
              visible={showModal}
              onViewNextSkill={handleViewNextSkill}
              onViewPreviousSkill={handleViewPreviousSkill}
              handleAddSkill={handleAddSkill}
              skillsSelected={skillsSelected}
            />
          )}
        </Container>
      ) : (
        <Container>
          <H2 margin="30px 0px 24px 0px">¿Cómo deseas agregar las competencias de este proceso?</H2>
          <H4
            style={{
              width: '100%',
              color: '#7E838A',
            }}
            margin="30px 0px 24px 0px"
          >
            Proceso por modelo de competencias propio
          </H4>
          <SelectContainer style={{ width: '440px' }}>
            <Select
              options={modelOptions}
              value={selectedModel}
              onChange={handleModelSelected}
              label="Selecciona el modelo de competencias"
              hideNeverChoice={true}
            />
          </SelectContainer>
          <ButtonContainer>
            <Button onClick={handleNextWithModel} disabled={!selectedModel} style={{ width: '250px' }} type="button">
              Siguiente
            </Button>
          </ButtonContainer>
          <LineContainer>
            <Line />
            <H5 color="#cbcaca" style={{ margin: '0 20px' }}>
              ó
            </H5>
            <Line />
          </LineContainer>
          <H4
            style={{
              width: '100%',
              color: '#7E838A',
              margin: '0px 0px 24px 0px',
            }}
          >
            Agregar competencias 3D Skills
          </H4>
          <CardTypeTest
            title={t('global.common.skillText')}
            image={boxImage}
            onClick={handleSelect3DSkills}
            checked={selected3DSkills}
          />
          <ButtonContainer>
            <Button
              onClick={handleNextWith3DSkills}
              disabled={!selected3DSkills}
              style={{ width: '250px' }}
              type="button"
            >
              Siguiente
            </Button>
          </ButtonContainer>
          <NextButtonContainer>
            <BackButton
              onClick={() => {
                if (stepThreeData.useBetesaTest) {
                  navigate(platformAdminRoutes.IdealProfileBetesa)
                } else {
                  navigate(platformAdminRoutes.SelectTestsType)
                }
              }}
            />
          </NextButtonContainer>
        </Container>
      )}
      <ModalLoader isLoading={loading} />
    </MainLayout>
  )
}

export default ProcessSkills
