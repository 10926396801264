import axios from 'lib/axios'

export const getHomologationExcel = async (processId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/skills/createExcel`

  const body = {
    processId,
  }

  try {
    const response = await axios.post(url, body, { responseType: 'blob' })
    return response.data
  } catch (error) {
    console.error(error)
  }
}
