import { AuthLayout } from 'views/layouts'

import LoginFormContainer from '../../containers/Forms/Login'
import {  ContainerForm  } from './styled'

const Login = () => {

  return (
    <AuthLayout>
      <ContainerForm>
        <LoginFormContainer />
      </ContainerForm>
    </AuthLayout>
  )
}

export default Login
