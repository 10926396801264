import { useState } from 'react'
import { useSelector } from 'react-redux'
import { companyIdSelector } from 'ducks/user'
import { createMultipleUsersService } from 'views/screens/Platform-administrator/services'

const DEFAULT_EVALUATED_USER_ROLE_TYPE = 4

interface UserData {
  firstName: string
  lastName: string
  document: string
  email: string
  documentType: number
  reason?: string
}

interface Result {
  user: UserData
  status: string
  error?: any
}

const useCreateUsersBatch = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [resultsSucess, setResultsSucces] = useState<Result[]>([])
  const [resultsFail, setResultsFail] = useState<Result[]>([])
  const [urlExcelUsersFailed, setUrlExcelUsersFailed] = useState<string>('')

  const companyId = useSelector(companyIdSelector)

  const createUsersBatch = async (users: UserData[]) => {
    setLoading(true)
    setError(null)
    setResultsSucces([])
    setResultsFail([])

    const userList = users.map((user: UserData) => {
      return {
        first_name: user.firstName,
        last_name: user.lastName,
        document: user.document.toString().trim(),
        email: user.email.toLowerCase(),
        document_type: user.documentType,
        role_type: DEFAULT_EVALUATED_USER_ROLE_TYPE,
        company_id: companyId,
      }
    })

    const body = {
      users: userList,
    }

    try {
      const response = await createMultipleUsersService(body)

      const resultsSuccess = response.data.createdUsers.map((user) => {
        return {
          user: {
            id: user.id,
            userId: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            document: user.document,
            email: user.email,
            documentType: user.document_type,
          },
          status: 'success',
        }
      })

      const resultsFailed = response.data.failedUsers.map((user) => {
        return {
          user: {
            firstName: user.first_name,
            lastName: user.last_name,
            document: user.document,
            email: user.email,
            documentType: user.document_type,
            reason: user.reason,
          },
          status: 'error',
        }
      })

      setResultsSucces(resultsSuccess)
      setResultsFail(resultsFailed)
      setUrlExcelUsersFailed(response?.data?.faild_users_file || '')

      setLoading(false)
    } catch (e) {
      setError('Error creating users batch.')
      setLoading(false)
    }
  }

  return { createUsersBatch, loading, error, resultsSucess, resultsFail, urlExcelUsersFailed }
}

export default useCreateUsersBatch
