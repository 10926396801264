import { useEffect, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface UsePreventNavigationProps {
  shouldPrevent: boolean
  onNavigationAttempt: () => Promise<void>
}

export const usePreventNavigation = ({ shouldPrevent, onNavigationAttempt }: UsePreventNavigationProps) => {
  const navigate = useNavigate()
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (shouldPrevent) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    [shouldPrevent]
  )

  const handleLinkClick = useCallback(
    (event: MouseEvent) => {
      if (!shouldPrevent) return

      const target = (event.target as Element).closest('a')
      if (!target) return

      if (
        target.target === '_blank' ||
        target.hasAttribute('download') ||
        target.href.startsWith('mailto:') ||
        target.href.startsWith('tel:')
      ) {
        return
      }

      event.preventDefault()
      const newPath = target.getAttribute('href')
      setPendingNavigation(newPath || '/')
      setIsModalOpen(true)
    },
    [shouldPrevent]
  )

  const handleConfirm = async () => {
    try {
      await onNavigationAttempt()
      setIsModalOpen(false)
      if (pendingNavigation) {
        navigate(pendingNavigation)
      }
    } catch (error) {
      console.error('Error al limpiar datos:', error)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
    setPendingNavigation(null)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    document.addEventListener('click', handleLinkClick, true)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      document.removeEventListener('click', handleLinkClick, true)
    }
  }, [handleBeforeUnload, handleLinkClick])

  return { isModalOpen, handleClose, handleConfirm }
}
