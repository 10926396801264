/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import { userSelector } from 'ducks/user'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import {
  CompanyCard,
  FormContainer,
  FormContainerItem,
  IconSvgProfile,
  LabelDiv,
  Label,
  InformationCompany,
  Row,
  CardContainer,
  Card,
  CardDescriptionSection,
} from './styled'
import { P } from 'views/components/UI/Text'
import { superAdminRoutes } from 'router/routes'
import getOneCompany from 'services/companies/getOneCompany'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { Company as CompanyType } from 'types/common'
import getIntegrations, { IIntegration } from 'services/companies/getIntegrations/getIntegrations'

const Company: FC = () => {
  const navigate = useNavigate()

  const { userId, token } = useSelector(userSelector)

  const [params, setParams] = useState<any>(null)
  const [idCurrentCompany, setIdCurrentCompany] = useState<any>(null)
  const [editUrl, setEditUrl] = useState<any>(null)

  const [companyInfo, setCompanyInfo] = useState<CompanyType>()
  const [dataValidation, setDataValidation] = useState<boolean>(false)
  const [integrations, setIntegrations] = useState<IIntegration[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getIntegrations().then((res) => {
      if (res.status === 'ok') {
        setIntegrations(res.data)
      } else {
        console.error('error on get integrations')
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getCompanyInfo() {
    setLoading(true)
    getOneCompany(idCurrentCompany)
      .then((response: any) => {
        setLoading(false)
        setCompanyInfo(response.data)
      })
      .catch(() => {
        setDataValidation(true)
      })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setParams(Object.fromEntries(searchParams))
  }, [])

  useEffect(() => {
    if (params) {
      setIdCurrentCompany(parseInt(params.companyId))
      setEditUrl(`/companies/update?companyId=${params.companyId}`)
    }
  }, [params])

  // Get company info
  useEffect(() => {
    if (idCurrentCompany && token && userId) {
      getCompanyInfo()
    }
  }, [idCurrentCompany, token, userId])

  useEffect(() => {
    if (dataValidation === true) {
      getCompanyInfo()
    }
  }, [idCurrentCompany, token, userId])

  const renderIntegrationName = (id: number) => {
    const integration = integrations.find((item) => item.id === id)
    return integration?.name
  }

  return (
    <MainLayout>
      <div style={{ paddingBottom: 40 }}>
        {companyInfo ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 35,
                alignItems: 'center',
              }}
            >
              <h2 style={{ fontSize: 30 }}>Información - {companyInfo.name}</h2>
            </div>
            <CardContainer style={{ paddingBottom: 20 }}>
              <Card>
                <Link to={`/inventory?companyId=${companyInfo.id}`} style={{ width: '100%', height: '100%' }}>
                  <CardDescriptionSection>
                    <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Inventarios</span>
                  </CardDescriptionSection>
                </Link>
              </Card>
              <Card>
                <Link
                  to="/company/admin-platform-per-company"
                  state={{ companyId: idCurrentCompany }}
                  style={{ width: '100%', height: '100%' }}
                >
                  <CardDescriptionSection>
                    <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>
                      Usuarios administradores de plataforma
                    </span>
                  </CardDescriptionSection>
                </Link>
              </Card>
              <Card
                onClick={() => {
                  navigate(superAdminRoutes.EvaluatedUserByCompany, {
                    state: { companyId: companyInfo.id, companyName: companyInfo.name },
                  })
                }}
              >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Usuarios evaluados</span>
                </CardDescriptionSection>
              </Card>
              <Card>
                <Link
                  to="/company/homologation-skills"
                  state={{ companyId: idCurrentCompany, companyName: companyInfo.name }}
                  style={{ width: '100%', height: '100%' }}
                >
                  <CardDescriptionSection>
                    <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>
                      Homologación de competencias
                    </span>
                  </CardDescriptionSection>
                </Link>
              </Card>
            </CardContainer>
            <CompanyCard>
              <Link to={editUrl}>
                <div
                  style={{
                    display: 'flex',
                    float: 'right',
                    color: '#2D749C',
                    fontSize: 15,
                    fontWeight: 'bold',
                    alignItems: 'center',
                    gap: 5,
                  }}
                >
                  <IconSvgProfile
                    clipRule="evenodd"
                    fillRule="evenodd"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m19 20.25c0-.402-.356-.75-.75-.75-2.561 0-11.939 0-14.5 0-.394 0-.75.348-.75.75s.356.75.75.75h14.5c.394 0 .75-.348.75-.75zm-7.403-3.398 9.124-9.125c.171-.171.279-.423.279-.684 0-.229-.083-.466-.28-.662l-3.115-3.104c-.185-.185-.429-.277-.672-.277s-.486.092-.672.277l-9.143 9.103c-.569 1.763-1.555 4.823-1.626 5.081-.02.075-.029.15-.029.224 0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342 2.137 2.137-3.168 1.046zm.955-1.166 7.651-7.616 2.335 2.327-7.637 7.638z"
                      fillRule="nonzero"
                    />
                  </IconSvgProfile>
                  <label style={{ cursor: 'pointer' }}>Editar perfil</label>
                </div>
              </Link>
              <FormContainer>
                <InformationCompany>
                  <img
                    src={companyInfo.logo}
                    style={{ width: 200, height: 200, objectFit: 'contain' }}
                    alt={`Logo de ${companyInfo.name}`}
                  />
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <IconSvgProfile
                        style={{ marginRight: '20px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M16 4h8v19h-24v-19h8v-2c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v2zm7 1h-22v17h22v-17zm-3 4v1h-16v-1h16zm-5-6.5c0-.133-.053-.26-.146-.354-.094-.093-.221-.146-.354-.146h-5c-.133 0-.26.053-.354.146-.093.094-.146.221-.146.354v1.5h6v-1.5z" />
                      </IconSvgProfile>
                      <Label>Nombre</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P color="#1F2124">{companyInfo.name}</P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <IconSvgProfile
                        style={{ marginRight: '20px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                      </IconSvgProfile>
                      <Label htmlFor="">Operativo</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P color="#1F2124">{companyInfo.operative}</P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <IconSvgProfile
                        style={{ marginRight: '20px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                      </IconSvgProfile>
                      <Label htmlFor="">Contacto</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P color="#1F2124">{companyInfo.contact_name}</P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <IconSvgProfile
                        style={{ marginRight: '20px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                      </IconSvgProfile>
                      <Label>Email</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                        {companyInfo.contact_email}
                      </P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <IconSvgProfile
                        style={{ marginRight: '20px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                      </IconSvgProfile>
                      <Label>Teléfono</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                        {companyInfo.phone}
                      </P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <Label>Comercial encargado</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                        {companyInfo.commercial_name || 'No asignado'}
                      </P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem>
                    <Row width="40%" justifyContent="flex-start">
                      <Label>Categoría</Label>
                    </Row>
                    <Row width="60%" justifyContent="flex-start">
                      <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                        {companyInfo.categoryName || 'No asignada'}
                      </P>
                    </Row>
                  </FormContainerItem>
                  <FormContainerItem marginTop="-20px" justifyContent="flex-start">
                    <LabelDiv alignItems="flex-start">
                      <Label>Carácter</Label>
                    </LabelDiv>
                    <LabelDiv alignItems="flex-start">
                      <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                        {companyInfo.caracter}
                      </P>
                    </LabelDiv>
                  </FormContainerItem>
                  <FormContainerItem marginTop="-20px" justifyContent="flex-start">
                    <LabelDiv alignItems="flex-start">
                      <Label>Integración</Label>
                    </LabelDiv>
                    <LabelDiv alignItems="flex-start">
                      <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                        {companyInfo.allow_integration ? 'Si' : 'No'}
                      </P>
                    </LabelDiv>
                  </FormContainerItem>
                  {companyInfo.allow_integration && (
                    <FormContainerItem marginTop="-20px" justifyContent="flex-start">
                      <LabelDiv alignItems="flex-start">
                        <Label>Tipo de integración</Label>
                      </LabelDiv>
                      <LabelDiv alignItems="flex-start">
                        <P style={{ wordBreak: 'break-all' }} color="#1F2124">
                          {renderIntegrationName(companyInfo.integrationType)}
                        </P>
                      </LabelDiv>
                    </FormContainerItem>
                  )}
                </InformationCompany>
              </FormContainer>
            </CompanyCard>
          </>
        ) : (
          <ModalLoader isLoading={loading} />
        )}
      </div>
    </MainLayout>
  )
}

export default Company
