import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import InputRadio from 'views/components/UI/InputRadio'
import TextInput from 'views/components/UI/TextInput'

import {
  isUpdatedSelector,
  processDetailSelector,
  saveChangesSelector,
  setIsDirty,
  setIsUpdated,
  setSaveChanges,
} from '../../store'
import { InfoWrapper, Subtitle, Title } from '../../styles.styled'
import {
  AskOptionText,
  ButtonWrapper,
  Container,
  HomologationButtonContainer,
  InfoText,
  NameProcessWrapper,
  Text,
  TopContent,
  WrapperInfoItem,
  WrapperItem,
} from './styles.styled'

import { Button } from 'views/components/UI/Buttons'
import { useLocation } from 'react-router-dom'
import useProcess from '../../hooks/useProcess'
import { getModelWithProcess } from 'views/screens/super-admin/homologation-skills/services/getModelWithProcess'
import { getHomologationExcel } from 'views/screens/super-admin/homologation-skills/services/getHomologationExcel'
import { useNotification } from 'lib/context/notification.context'

const GeneralInfo = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { getError } = useNotification()
  const processId = location.state?.processId as number
  const processDetail = useSelector(processDetailSelector)
  const processCreationDate = dayjs(processDetail?.processCreatedAt).format('DD/MM/YYYY')
  const isUpdated = useSelector(isUpdatedSelector)
  const saveChanges = useSelector(saveChangesSelector)
  const { updateProcessDetails } = useProcess()
  const [hasHomologation, setHasHomologation] = useState(false)

  const textWidthRef = useRef<HTMLSpanElement>(null)

  const [inputWidth, setInputWidth] = useState('50px')

  const { values, setFieldValue, dirty, handleSubmit } = useFormik({
    initialValues: {
      processType: 0,
      processName: processDetail?.processName || '',
      inventoryType: '',
      level: '',
      automaticReports: processDetail?.hasAutoDeliveryReport || false,
      permitDevelopmentPlan: processDetail?.hasCreateDevelopmentPlan || false,
    },
    enableReinitialize: false,
    onSubmit: async (finalValues, { resetForm }): Promise<void> => {
      const updateProcessDetailsBody = {
        processName: finalValues.processName,
        hasAutoDeliveryReport: finalValues.automaticReports,
        hasCreateDevelopmentPlan: finalValues.permitDevelopmentPlan,
      }

      await updateProcessDetails(processId, updateProcessDetailsBody)
      dispatch(setIsUpdated(true))
      resetForm({
        values: finalValues,
      })
    },
  })

  useEffect(() => {
    getModelWithProcess(processId)
      .then((res) => {
        if (res.status === 'success') {
          setHasHomologation(true)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [processId])

  useEffect(() => {
    const updateInputWidth = () => {
      if (textWidthRef.current) {
        const textWidth = textWidthRef.current.offsetWidth + 20
        setInputWidth(`${textWidth + 2}px`)
      }
    }
    updateInputWidth()
  }, [values.processName])

  useEffect(() => {
    dispatch(setSaveChanges(false))
  }, [dispatch])

  useEffect(() => {
    dispatch(setIsDirty(dirty))

    if (dirty) {
      dispatch(setIsUpdated(false))
    }
  }, [dispatch, values, dirty])

  useEffect(() => {
    if (dirty && saveChanges) {
      handleSubmit()
      dispatch(setIsDirty(false))
      dispatch(setIsUpdated(false))
    }
  }, [dispatch, saveChanges, dirty, handleSubmit])

  const handleDownloadHomologationExcel = () => {
    if (processId) {
      getHomologationExcel(processId)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', `Homologación_${processDetail?.processName}.xlsx`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch((error) => {
          getError('Ocurrió un error al generar el archivo de homologación')
          console.error(error)
        })
    }
  }

  return (
    <Container>
      <Title>Parametrización del proceso {processDetail?.processName}</Title>
      <Subtitle>Información general del proceso</Subtitle>
      <TopContent>
        {hasHomologation ? (
          <HomologationButtonContainer>
            <Button onClick={handleDownloadHomologationExcel} disabled={!processDetail?.hasCompletedCompetenceTest}>
              Homologación
            </Button>
          </HomologationButtonContainer>
        ) : null}
        <InfoWrapper>
          <WrapperInfoItem>
            <NameProcessWrapper>
              <Text>Nombre del proceso:</Text>
            </NameProcessWrapper>
            <Text>Fecha de creación:</Text>
            <Text>Tipo del proceso:</Text>
            <Text>Inventario:</Text>
            <Text>Nivel:</Text>
            <Text>Creador del proceso:</Text>
          </WrapperInfoItem>
          <WrapperInfoItem style={{ width: '500px' }}>
            <TextInput
              value={values?.processName}
              onChange={(e) => setFieldValue('processName', e.target.value)}
              style={{
                width: inputWidth,
                minWidth: '300px',
              }}
              name="skill"
              type="text"
              label="Nombre del proceso"
              placeholder="Nombre del proceso"
            />
            <span
              ref={textWidthRef}
              style={{
                position: 'absolute',
                visibility: 'hidden',
                height: 'auto',
                width: 'auto',
                whiteSpace: 'nowrap',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                letterSpacing: 'inherit',
                padding: 'inherit',
              }}
            >
              {values.processName || 'Nombre del proceso'}
            </span>
            <InfoText>{processCreationDate}</InfoText>
            <InfoText>{processDetail?.processType === 'Contratación' ? 'Selección' : 'Desarrollo'}</InfoText>
            <InfoText>{processDetail?.subscriptionName}</InfoText>
            <InfoText>{processDetail?.levelName}</InfoText>
            <InfoText>{processDetail?.username}</InfoText>
          </WrapperInfoItem>
        </InfoWrapper>
      </TopContent>
      <WrapperItem>
        <AskOptionText>¿Entrega automática de los reportes a los participantes?</AskOptionText>
        <InputRadio
          checked={values.automaticReports}
          label="Si"
          value="Si"
          onChange={() => setFieldValue('automaticReports', true)}
          name="automaticReports"
        />
        <InputRadio
          checked={values.automaticReports === (false as any)}
          label="No"
          value="No"
          onChange={() => setFieldValue('automaticReports', false)}
          name="automaticReports"
        />
      </WrapperItem>
      <WrapperItem>
        <AskOptionText style={{ marginRight: '24px' }}>
          ¿Permitir creación de plan de desarrollo por usuario?
        </AskOptionText>
        <InputRadio
          checked={values.permitDevelopmentPlan}
          label="Si"
          value="Si"
          onChange={() => setFieldValue('permitDevelopmentPlan', true)}
          name="permitDevelopmentPlan"
        />
        <InputRadio
          checked={values.permitDevelopmentPlan === (false as any)}
          label="No"
          value="No"
          onChange={() => setFieldValue('permitDevelopmentPlan', false)}
          name="permitDevelopmentPlan"
        />
        <ButtonWrapper>
          <Button type="submit" disabled={!dirty || isUpdated || !values.processName} onClick={handleSubmit}>
            Guardar
          </Button>
        </ButtonWrapper>
      </WrapperItem>
    </Container>
  )
}

export default GeneralInfo
