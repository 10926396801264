import { FC, useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from 'ducks/user'
import { generalDataSelector } from 'ducks/auth'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { MainLayout } from 'views/layouts'
import Select from 'views/components/UI/Select'
import { TopContainer, UserTypeContainer } from './styled'
import { ActionViewEditUser } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { StrokedButton } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'
import getUsers from 'services/common/users/get/getUsers'
import createSuperAdmin from 'services/common/users/create-user'
import { IDS, HEADERS, determineRole, parseRole } from './constants'
import NewSuperAdmin from './new-super-admin-modal/new-super-admin'
import EditPlatformAdminModal from 'views/screens/super-admin/admin-platform-company/pages/edit-admin-platform-modal/edit-admin-platform-modal'
import { fetchSearchUser } from './services/fetch-users'
import { setUsers, usersSelector } from './store'
import { debounce } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

interface BodyType {
  search: string
  role?: number[]
  excludeEmailSearch?: boolean
  page?: number
}

const AllUsers: FC = () => {
  const { getSuccess, getError } = useNotification()
  const { token } = useSelector(userSelector)
  const { data: generalData } = useSelector(generalDataSelector)
  const [rolesOptions, setRolesOptions] = useState<any[] | []>([])
  const [userType, setUserType] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState<number | undefined>(0)
  const [idUserSelected, setIdUserSelected] = useState<number | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const { getWarning } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { users } = useSelector(usersSelector)

  const handleShowAllUsers = useCallback(
    (roles: any, page = 1, limit = 10) => {
      if (token) {
        const userRole = roles === 0 ? IDS : [roles]

        setIsLoading(true)
        getUsers(token, { role: userRole }, { page: page, limit: limit }).then((res) => {
          if (res.status === 'OK') {
            const usersFullName = res.users.map((user: any) => {
              return {
                ...user,
                first_name: `${user.first_name} ${user.last_name}`,
                role: parseRole(user.role),
              }
            })

            dispatch(setUsers(usersFullName))
            setNumberOfPages(res.total_pages)
            setIsLoading(false)
          }
        })
      }
    },
    [token, dispatch]
  )

  const handleGetByRole = useCallback(
    (role: number, page = 1, limit = 10) => {
      if (token && (role === 0 || role === 3 || role === 4 || role === 6)) {
        setIsLoading(true)
        if (role === 0) {
          handleShowAllUsers(role, page, limit)
          setFilterActive(false)
        } else {
          const manyRoles = [role]
          getUsers(token, { role: manyRoles }, { page: page, limit: limit }).then((res) => {
            if (res.status === 'OK') {
              const usersFullName = res.users.map((user: any) => {
                return {
                  ...user,
                  first_name: `${user.first_name} ${user.last_name}`,
                  role: parseRole(user.role),
                }
              })

              dispatch(setUsers(usersFullName))
              setNumberOfPages(res.total_pages)
              setFilterActive(true)
              setIsLoading(false)
            } else {
              getError('Error al obtener los usuarios')
              setIsLoading(false)
            }
          })
        }
      }
    },
    [token, handleShowAllUsers, getError, dispatch]
  )

  useEffect(() => {
    if (generalData?.role_type) {
      const roles = generalData?.role_type?.filter((role: any) => role.id !== 1 && role.id !== 2 && role.id !== 5)
      const rolesOptionsFormatted = roles?.map((role: any) => {
        return {
          value: role.id,
          label: determineRole(role),
        }
      })
      setRolesOptions(rolesOptionsFormatted)
    }
  }, [token, currentPage, generalData])

  const fetchSearchData = (() => {
    let lastSearchTime = 0

    return async (searchValue: string, isLiveSearch?: boolean) => {
      setIsLoading(true)
      try {
        if (searchValue.length <= 0) return handleShowAllUsers(userType, currentPage, 10)

        const thisSearchTime = Date.now()
        lastSearchTime = thisSearchTime

        const body: BodyType = {
          search: searchValue,
        }

        if (userType !== 0) {
          body.role = [userType]
        }

        setSearchParam(searchValue)

        const res = await fetchSearchUser(body, currentPage)

        if (thisSearchTime !== lastSearchTime) {
          return
        }

        if (res.status === 'OK' || res.status === 'success') {
          const usersFullName = res?.users?.map((user: any) => ({
            ...user,
            first_name: `${user.first_name} ${user.last_name}`,
            role: parseRole(user.role),
          }))

          dispatch(setUsers(usersFullName || []))
          setNumberOfPages(res?.total_pages)
          setIsLoading(false)
        } else {
          getWarning('Los datos ingresados no pudieron ser encontrados')
          dispatch(setUsers([]))
          setNumberOfPages(1)
          setIsLoading(false)
        }
      } catch (err) {
        if (!isLiveSearch) getWarning('No puedes realizar búsqueda sin ingresar ningún valor')
        setIsLoading(false)
      }
    }
  })()

  const handleSearch = useCallback((searchValue: string) => {
    if (searchValue === '') {
      setCurrentPage(1)
      setSearchParam('')
    } else {
      fetchSearchData(searchValue, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedSearch = useMemo(() => debounce(handleSearch, 500), [handleSearch])

  useEffect(() => {
    if (filterActive && searchParam.length === 0) {
      handleGetByRole(userType, currentPage, 10)
    } else if (searchParam.length === 0) {
      handleShowAllUsers(userType, currentPage, 10)
    } else {
      fetchSearchData(searchParam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    if (searchParam.length <= 0) {
      handleShowAllUsers(userType)
      setFilterActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, handleShowAllUsers])

  useEffect(() => {
    if (userType || userType === 0) {
      handleGetByRole(Number(userType))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType])

  const handleCreateSuperAdmin = (data: any) => {
    const dataToCreate = {
      ...data,
      document: uuidv4(),
      document_type: 1,
      role_type: 2,
    }

    createSuperAdmin(dataToCreate).then((res) => {
      if (res.status === 'OK' || res.status === 'success') {
        setShowModal(false)
        getSuccess('Super administrador creado con éxito')
      } else {
        getError('Error al crear el super administrador')

        if (!res.ok) {
          getError('Error: ' + res.message)
        }
      }
    })
  }

  const handleSelectUser = (value: string) => {
    setUserType(Number(value))
    setCurrentPage(1)
  }

  return (
    <MainLayout>
      <TopContainer>
        <h2 style={{ fontSize: 32 }}>Usuarios</h2>
        <StrokedButton onClick={() => setShowModal(true)}>Agregar nuevo super administrador</StrokedButton>
      </TopContainer>
      <UserTypeContainer>
        <p style={{ color: 'black', fontSize: 25, width: '100%' }}>Tipo de usuario</p>
        {rolesOptions.length > 0 && (
          <Select
            value={userType}
            label="Tipo de usuario"
            options={rolesOptions}
            onChange={(value: string) => {
              handleSelectUser(value)
            }}
          />
        )}
      </UserTypeContainer>
      <BluesiteTableComponent
        loading={isLoading}
        ActionComponent={ActionViewEditUser}
        data={users}
        headers={HEADERS}
        searchBar={true}
        showNumbers={true}
        setNewPage={setCurrentPage}
        totalPages={numberOfPages}
        currentPage={currentPage}
        searchLabel="Buscar por:"
        styleSearchBar={{ width: '350px', marginTop: '10px' }}
        handlePagination={setCurrentPage}
        setSearchParam={setSearchParam}
        fetchSearchData={fetchSearchData}
        onSearch={debouncedSearch}
        extraDataAction={{
          onSelectUserId: (id: number) => {
            setIdUserSelected(id)
            setTimeout(() => {
              setModalEditOpen(true)
            }, 100)
          },
        }}
      />
      <NewSuperAdmin openModal={showModal} setOpenModal={setShowModal} onSubmit={(e) => handleCreateSuperAdmin(e)} />
      {modalEditOpen === true && idUserSelected && (
        <EditPlatformAdminModal
          idUserSelected={idUserSelected}
          openModal={modalEditOpen}
          setOpenModal={setModalEditOpen}
          handleCloseClick={() => {
            fetchSearchData('')
            setSearchParam('')
            setModalEditOpen(false)
          }}
        />
      )}
    </MainLayout>
  )
}

export default AllUsers
