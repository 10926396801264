import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setTestTypes } from 'ducks/createProcess'
import { getUsersByProcess, getProcessDetail } from 'services/common/process/createProcess/create-process-service'
import { useTranslation } from 'react-i18next'

type UserData = {
  id: string
  first_name: string
  last_name: string
  company_name: string
  document: string
  email: string
  test_types: string
}

const useGetUsersByProcess = (stepOneData: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isUsersLoaded, setIsUsersLoaded] = useState(false)
  const [addedUsers, setAddedUsers] = useState<UserData[]>([])
  const [isProcessFinished, setIsProcessFinished] = useState(false)

  useEffect(() => {
    if (stepOneData.processId) {
      getProcessDetail(stepOneData.processId).then((response: any) => {
        if (response.status === 'OK') {
          const tesTypesSelected = `${response?.process.betesa_test ? 'Betesa - ' : ''}${
            response?.process?.competences_test ? t('global.common.skillText') : ''
          }`
          setIsProcessFinished(response?.process?.sent)
          dispatch(setTestTypes(tesTypesSelected))
          getUsersByProcess(stepOneData.processId, { limit: 1000000, viewUsersWithoutTests: true }).then((res: any) => {
            if (res.status === 'OK') {
              const users = res.data.map((user: any) => ({
                id: user.userId,
                first_name: user.first_name,
                last_name: user.last_name,
                company_name: user.company_name,
                document: user.document,
                email: user.email,
                test_types: tesTypesSelected,
                test_sent: user.test_sent,
                active: user.active,
                language: user.language,
              }))
              setIsUsersLoaded(true)
              setAddedUsers(users.reverse())
            }
          })
        }
      })
    }
  }, [stepOneData.processId, isUsersLoaded, dispatch, t])

  return { isUsersLoaded, addedUsers, isProcessFinished }
}

export default useGetUsersByProcess
