import axios from 'lib/axios'

export interface BodyProps {
  name: string
  companyId: number
  modelId: number
}

export const createSkill = async (body: BodyProps) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/skills`
  try {
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
