import axios from 'lib/axios'

export const getCompanySkills = async (companyId: number, modelId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/skills/${companyId}/${modelId}`
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
