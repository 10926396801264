import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'

import { Container, InputsContainer, InputContainer, ErrorContainer, Error, TextArea } from './styled'
import Input from 'views/components/UI/TextInput'
import Select from 'views/components/UI/Select'
import DatePicker from 'views/components/UI/Datepicker'
import { Button } from 'views/components/UI/Buttons'

import { generalDataSelector } from 'ducks/auth'
import { tokenSelector } from 'ducks/user'
import { BACK_PAGE } from '../constants'
import createNewInventory from 'services/inventory/new'
import { useNotification } from 'lib/context/notification.context'
import { BtnGoBack, ContainerRow } from 'views/components/Modal/styled'
import dayjs from 'dayjs'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('El nombre del inventario es requerido'),
  type: Yup.string().required('Debe seleccionar un tipo de inventario'),
  credits: Yup.number().min(0, 'El mínimo de pruebas es 0').required('Este campo es requerido'),
  expirationDate: Yup.date().required('La fecha de vencimiento es requerida'),
  dateValidation: Yup.date().test('dateValidation', 'No puedes seleccionar una fecha anterior a hoy', function (value) {
    const selectedDate = dayjs(value)
    const currentDate = dayjs()

    return selectedDate.isAfter(currentDate) || selectedDate.isSame(currentDate, 'day')
  }),
  comments: Yup.string(),
})

function Index() {
  const { getWarning, getSuccess } = useNotification()
  const navigate = useNavigate()
  const { data } = useSelector(generalDataSelector)
  const token = useSelector(tokenSelector)

  const [params, setParams] = useState<any>({})
  const [idCompany, setIdCompany] = useState<number | null>(null)
  const [optionsType, setOptionsType] = useState<any>([])

  useEffect(() => {
    if (data.hasOwnProperty('subscription')) {
      const options = _.map(data.subscription, (item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setOptionsType(options)
    } else {
      setOptionsType([])
    }

    const searchParams = new URLSearchParams(window.location.search)
    setParams(Object.fromEntries(searchParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      const companyId = parseInt(params.companyId)
      setIdCompany(companyId)
    }
  }, [params])

  interface IBodyData {
    expirationDate: string | null
    name: string
    type: string
    credits: string
    comments: string
  }

  function handleCreateInventory(values: IBodyData) {
    const { expirationDate, name, type, credits, comments } = values
    const bodyData = {
      subscriptionId: parseInt(type),
      companyId: idCompany,
      testUsed: 0,
      expirationDate: expirationDate,
      name,
      credits: parseInt(credits),
      comments,
    }

    createNewInventory(token, bodyData).then((res) => {
      if (res.status === 'success') {
        getSuccess('Inventario creado correctamente')
        navigate(BACK_PAGE)
      } else {
        getWarning(
          'Error al crear el inventario, verifica que todos los campos estén llenos y que la candidad de pruebas sea mayor a cero'
        )
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      credits: '0',
      expirationDate: null,
      dateValidation: null,
      comments: '',
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      if (values.type.toString() === '3' || values.type.toString() === '4') {
        values.credits = '1000000000'
      }
      handleCreateInventory(values)
    },
  })

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <Container>
          <h1>Nuevo inventario</h1>
          <InputsContainer>
            <InputContainer>
              <Input
                type="text"
                name="name"
                label="Nombre"
                placeholder="Nombre del inventario"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <ErrorContainer>
                  {' '}
                  <Error>{formik.errors.name}</Error>{' '}
                </ErrorContainer>
              ) : null}
            </InputContainer>
            <InputContainer>
              <Select
                hideNeverChoice={true}
                label="Tipo"
                options={optionsType || []}
                onChange={(e: string) => {
                  formik.setFieldValue('type', e)
                }}
                value={formik.values.type}
              />
              {formik.touched.type && formik.errors.type ? (
                <ErrorContainer>
                  {' '}
                  <Error>{formik.errors.type}</Error>{' '}
                </ErrorContainer>
              ) : null}
            </InputContainer>
            <InputContainer>
              <Input
                type={formik.values.type.toString() !== '3' || formik.values.type.toString() !== '4' ? 'text' : 'number'}
                disabled={formik.values.type.toString() === '3' || formik.values.type.toString() === '4' ? true : false}
                name="credits"
                label="Cantidad de pruebas"
                placeholder="Cantidad de pruebas"
                onChange={(e: any) => {
                  const value = e.target.value
                  const intValue = parseInt(value)
                  if (value === '' || (intValue >= 0 && typeof intValue === 'number')) {
                    formik.handleChange('credits')(e)
                  }
                }}
                onBlur={formik.handleBlur}
                value={
                  formik.values.type.toString() === '3' || formik.values.type.toString() === '4'
                    ? '∞'
                    : formik.values.credits
                }
              />
              {formik.touched.credits && formik.errors.credits ? (
                <ErrorContainer>
                  {' '}
                  <Error>{formik.errors.credits}</Error>{' '}
                </ErrorContainer>
              ) : null}
            </InputContainer>
            <InputContainer>
              <DatePicker
                useISO8601={true}
                label="Fecha de vencimiento"
                onChange={(e) => {
                  const currentDate = new Date()
                  const today = dayjs(currentDate).format('YYYY-MM-DDTHH:mm:ssZ')
                  const selectedDate = dayjs(e).format('YYYY-MM-DDTHH:mm:ssZ')
                  formik.setFieldValue('expirationDate', e)

                  if (selectedDate < today) {
                    formik.setFieldValue('dateValidation', e)
                  } else {
                    formik.setFieldValue('dateValidation', '')
                  }
                  formik.validateField('expirationDate')
                }}
              />

              {formik.touched.expirationDate && formik.errors.expirationDate ? (
                <ErrorContainer>
                  <Error>{formik.errors.expirationDate}</Error>
                </ErrorContainer>
              ) : formik.touched.dateValidation && formik.errors.dateValidation ? (
                <ErrorContainer>
                  <Error>{formik.errors.dateValidation}</Error>
                </ErrorContainer>
              ) : null}
            </InputContainer>
            <TextArea
              name="comments"
              onChange={(e: any) => {
                formik.handleChange('comments')(e)
              }}
              value={formik.values.comments}
              placeholder="Notas"
            />
          </InputsContainer>
          <ContainerRow>
            <BtnGoBack onClick={() => navigate(-1)}>Cancelar</BtnGoBack>
            <Button disabled={!_.isEmpty(formik.errors)} type="submit">
              Guardar
            </Button>
          </ContainerRow>
        </Container>
      </form>
    </MainLayout>
  )
}

export default Index
