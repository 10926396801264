import axios from 'lib/axios'

export const deleteModelProcessAssociation = async (processId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/models/process/delete`

  const body = {
    processId,
  }
  try {
    const response = await axios.put(url, body)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
