import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from 'ducks/user'
import { Inventory } from 'types/common'
import getInventoriesByCompany from 'services/companies/getInventories/getInventories'

export const useGetInventoryById = (inventoryId: number, refreshInventory?: boolean) => {
  const { companyId } = useSelector(userSelector)

  const [inventoryData, setInventoryData] = useState<Inventory | null>(null)

  useEffect(() => {
    const getInventoryById = async () => {
      try {
        const response: any = await getInventoriesByCompany({ companyId, limit: 1000 })
        const inventory: Inventory = response.data.find((item: any) => item.suscription_company_id === inventoryId)
        setInventoryData(inventory)
      } catch (error) {
        console.error('An error ocurred while getting inventory: ', error)
      }
    }

    if (inventoryId !== undefined) {
      getInventoryById()
    }
  }, [companyId, inventoryId, refreshInventory])

  return { inventoryData, refreshInventory }
}

export const useGetCreditsAvailables = (inventoryId: number) => {
  const { companyId } = useSelector(userSelector)
  const [creditsAvailables, setCreditsAvailables] = useState<number | 'Ilimitado'>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const getCreditsAvailables = useCallback(async () => {
    setLoading(true)
    setTimeout(async () => {
      try {
        const response: any = await getInventoriesByCompany({ companyId, limit: 1000 })

        const inventory: Inventory = response.data.find((item: any) => item.suscription_company_id === inventoryId)

        const amountCreditsAvailables = inventory.credits - inventory.usedCredits

        if (inventory.subscriptionName === 'Ilimitado') {
          setCreditsAvailables('Ilimitado')
        } else {
          setCreditsAvailables(amountCreditsAvailables)
        }
      } catch (error) {
        console.error('An error occurred while getting inventory: ', error)
      } finally {
        setLoading(false)
      }
    }, 1500)
  }, [companyId, inventoryId])

  useEffect(() => {
    if (inventoryId !== undefined && companyId !== undefined) {
      getCreditsAvailables()
    }
  }, [companyId, inventoryId, getCreditsAvailables])

  const refreshCredits = () => {
    getCreditsAvailables()
  }

  return { creditsAvailables, loading, refreshCredits }
}
