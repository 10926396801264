import axios from 'lib/axios'

export const associateModelWithProcess = async (modelId: number, processId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/models/process`
  try {
    const body = {
      modelId,
      processId,
    }
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
