import { Typography } from '@mui/material'
import styled from 'styled-components'
interface TitleProps {
  margin?: string
}

export const Container = styled.div`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const ContentContainer = styled.div`
  padding: 3.5%;
`

export const Title = styled(Typography).attrs({
  variant: 'h2',
})<TitleProps>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.00735em;
    color: #303030;
    margin: ${(props) => props.margin};

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 540px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 20px;
`

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
`
export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 120px;
  padding: 30px;
  cursor: pointer;
`

export const CardTitle = styled.span`
  font-weight: bold;
  color: #1f2124;
  font-size: 20px;
`

export const CardDescriptionSection = styled.span`
  margin-top: 10px;
  font-weight: 500;
  color: #a3a4a6;
  font-size: 14px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
`

export const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  padding: 10px 40px;
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
`

export const CancelButton = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  color: #a2a2a2;
  cursor: pointer;
`
