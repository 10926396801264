import axios from 'lib/axios'

export const createModel = async (name: string) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/models/`
  try {
    const body = {
      name,
    }
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
