export const headers = [
  {
    key: 'name',
    name: 'Nombre',
  },
  {
    key: 'homologation',
    name: 'Homologación',
  },
  {
    key: 'integration',
    name: 'Integración',
  },
]
