import axios from 'lib/axios'

import { Response } from '../store'

export const fetchSearchUser = async (data: any, page?: number): Promise<Response> => {
  try {
    let apiUrl = `/user`

    if (page) {
      apiUrl = `${apiUrl}?page=${page || 1}`
    }
    const response = await axios.post(apiUrl, data)
    return response.data
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
