import { FC, useEffect, useState } from 'react'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { BackButton, Button } from 'views/components/UI/Buttons'
import {
  BottomContainer,
  ButtonContainer,
  Card,
  CardContainer,
  CardTitle,
  Container,
  ContentContainer,
  Head,
  ModalButtonsContainer,
  ModalTitle,
  Title,
} from './styled'
import AddIcon from '@mui/icons-material/Add'
import Modal from 'views/components/UI/Modal'
import Input from 'views/components/UI/TextInput'
import { useNotification } from 'lib/context/notification.context'
import CircularProgress from '@mui/material/CircularProgress'
import { createSkill } from '../../services/createSkill'
import { getCompanySkills } from '../../services/getCompanySkills'
import { deleteModel } from '../../services/deleteModel'
import { ModelsProps } from '../../homologationSkills'
import { editSkills } from '../../services/editSkills'
import { usePreventNavigation } from '../../hooks/usePreventNavigation'

interface Props {
  companyId: number
  modelData: ModelsProps
  setFormStep: (step: number) => void
  setModelInfo: (info: ModelsProps) => void
  setModelForm: (form: boolean) => void
}

interface SkillsProps {
  id: number
  name: string
  companyId: number
}

interface SkillState {
  isCreatingSkill: boolean
  isEditingSkill: boolean
  editingSkillId: number
  initialSkillValue: string
}

const SkillsCreation: FC<Props> = ({ companyId, modelData, setFormStep, setModelInfo, setModelForm }) => {
  const localModel = JSON.parse(localStorage.getItem('modelInfo') || '{}')
  const { getError } = useNotification()
  const [modelId] = useState<number>(modelData?.id || localModel.id)
  const [modelName] = useState<string>(modelData?.name || localModel.name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [isDeletingModel, setIsDeletingModel] = useState<boolean>(false)
  const [skillsCreated, setSkillsCreated] = useState<SkillsProps[]>([])
  const [skillState, setSkillState] = useState<SkillState>({
    isCreatingSkill: false,
    isEditingSkill: false,
    editingSkillId: 0,
    initialSkillValue: '',
  })

  const handleCleanup = async () => {
    if (modelId) {
      const res = await deleteModel(modelId)
      if (res.status === 'success') {
        setOpenConfirmModal(false)
        setIsDeletingModel(false)
        setModelInfo({} as ModelsProps)
        localStorage.removeItem('formStep')
        localStorage.removeItem('formActive')
        setModelForm(false)
      }
    }
  }

  // MARK: HOOK

  const { isModalOpen, handleClose, handleConfirm } = usePreventNavigation({
    shouldPrevent: true,
    onNavigationAttempt: handleCleanup,
  })

  const resetSkillState = () => {
    setSkillState({
      isCreatingSkill: false,
      isEditingSkill: false,
      editingSkillId: 0,
      initialSkillValue: '',
    })
  }

  const updateSkillState = (updates: Partial<SkillState>) => {
    setSkillState((prevState) => ({
      ...prevState,
      ...updates,
    }))
  }

  // MARK: SERVICES

  useEffect(() => {
    setIsLoading(true)
    getCompanySkills(companyId, modelId)
      .then((res) => {
        if (res.status === 'success') {
          setSkillsCreated(res.data.companySkills)
        }
        setIsLoading(false)
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener las competencias')
        setIsLoading(false)
      })
  }, [companyId, getError, modelId])

  const handleCreateSkill = () => {
    if (inputValue) {
      updateSkillState({ isCreatingSkill: true })
      const body = {
        name: inputValue.trim(),
        companyId,
        modelId,
      }
      createSkill(body)
        .then((res) => {
          if (res.status === 'success') {
            setSkillsCreated([...skillsCreated, res.data])
            setOpenModal(false)
            setInputValue('')
            updateSkillState({ isCreatingSkill: false })
          } else {
            getError('Ha ocurrido un error al intentar crear la competencia')
            updateSkillState({ isCreatingSkill: false })
          }
        })
        .catch(() => {
          getError('Ha ocurrido un error al intentar crear la competencia')
          updateSkillState({ isCreatingSkill: false })
        })
    }
  }

  const handleEditSkill = () => {
    if (inputValue && skillState.editingSkillId !== 0) {
      updateSkillState({ isEditingSkill: true })
      const body = {
        name: inputValue.trim(),
        skillId: skillState.editingSkillId,
      }
      editSkills(body)
        .then((res) => {
          if (res.status === 'success') {
            const updatedSkills = skillsCreated.map((skill) => {
              if (skill.id === skillState.editingSkillId) {
                return res.data
              }
              return skill
            })
            setSkillsCreated(updatedSkills)
            setOpenModal(false)
            setInputValue('')
            resetSkillState()
          } else {
            getError('Ha ocurrido un error al intentar editar la competencia')
            resetSkillState()
          }
        })
        .catch(() => {
          getError('Ha ocurrido un error al intentar editar la competencia')
          resetSkillState()
        })
    }
  }

  const handleDeleteModel = () => {
    setIsDeletingModel(true)
    handleConfirm()
    deleteModel(modelId)
      .then((res) => {
        if (res.status === 'success') {
          setOpenConfirmModal(false)
          setIsDeletingModel(false)
          setModelInfo({} as ModelsProps)
          localStorage.removeItem('formStep')
          localStorage.removeItem('formActive')
          setModelForm(false)
        } else {
          getError('Ha ocurrido un error al intentar eliminar el modelo de homologación')
          setIsDeletingModel(false)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar eliminar el modelo de homologación')
        setIsDeletingModel(false)
      })
  }

  // MARK: HANDLERS

  const handleOpenEditModal = (skill: SkillsProps) => {
    setInputValue(skill.name)
    updateSkillState({
      isEditingSkill: true,
      initialSkillValue: skill.name,
      editingSkillId: skill.id,
    })
    setOpenModal(true)
  }

  const handleNextStep = () => {
    setFormStep(2)
    localStorage.setItem('formStep', '2')
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setInputValue('')
    resetSkillState()
  }

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
    handleClose()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  if (isLoading) return <ModalLoader isLoading={isLoading} />

  // MARK: RENDER

  return (
    <Container>
      <Modal show={openModal} setShow={setOpenModal} hideCloseButton={true} onlyContent={false}>
        <ModalTitle>{skillState.isEditingSkill ? 'Editar competencia' : 'Crear nueva competencia'}</ModalTitle>
        <Input
          type="text"
          label="Nombre de la competencia"
          value={inputValue}
          onChange={handleInputChange}
          style={{ width: '70%', alignSelf: 'center', marginTop: '20px' }}
        />
        <ModalButtonsContainer>
          <BackButton onClick={handleCloseModal} text="Cancelar" hideIcon />
          <Button
            onClick={skillState.isEditingSkill ? handleEditSkill : handleCreateSkill}
            type="button"
            style={{ width: '140px' }}
            disabled={
              skillState.isCreatingSkill ||
              !inputValue.trim() ||
              (skillState.isEditingSkill && inputValue.trim() === skillState.initialSkillValue)
            }
          >
            {skillState.isCreatingSkill ? (
              <CircularProgress color="inherit" size="26px" />
            ) : skillState.isEditingSkill ? (
              'Guardar'
            ) : (
              'Crear'
            )}
          </Button>
        </ModalButtonsContainer>
      </Modal>
      <Modal
        show={openConfirmModal || isModalOpen}
        setShow={setOpenConfirmModal}
        hideCloseButton={true}
        onlyContent={false}
      >
        <ModalTitle style={{ fontSize: '24px' }}>Si confirmas, perderás todo el progreso</ModalTitle>
        <ModalButtonsContainer style={{ justifyContent: 'space-evenly' }}>
          <BackButton onClick={handleCloseConfirmModal} text="Cancelar" hideIcon />
          <Button onClick={handleDeleteModel} type="button" style={{ width: '140px' }} disabled={isDeletingModel}>
            {isDeletingModel ? <CircularProgress color="inherit" size="26px" /> : 'Confirmar'}
          </Button>
        </ModalButtonsContainer>
      </Modal>
      <ContentContainer>
        <Head>
          <Title>{modelName}</Title>
        </Head>
        <CardContainer>
          {skillsCreated.length > 0 &&
            skillsCreated.map((skill, index) => (
              <Card key={`list-skills-${index}`} onClick={() => handleOpenEditModal(skill)}>
                <CardTitle>{skill.name}</CardTitle>
              </Card>
            ))}
        </CardContainer>
        <ButtonContainer>
          <Button
            onClick={handleOpenModal}
            type="button"
            style={{ display: 'flex', alignItems: 'center', padding: '34px' }}
          >
            Agregar nueva competencia
            <AddIcon fontSize="large" sx={{ marginLeft: '10px' }} />
          </Button>
        </ButtonContainer>
      </ContentContainer>
      <BottomContainer>
        <BackButton onClick={handleOpenConfirmModal} text="Cancelar" hideIcon />
        <Button onClick={handleNextStep} disabled={!skillsCreated.length} type="button">
          Siguiente
        </Button>
      </BottomContainer>
    </Container>
  )
}

export default SkillsCreation
