import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  MinimumRecordContainer,
  StepsContainer,
  GridContainer,
  Row,
  GridMinimumRecord,
  GridItem,
  TitleGrid,
  Divider,
  ContainerWithNumbers,
} from '../styled'
import { StrokedButton } from 'views/components/UI/Buttons'
import { H2, H4, P } from 'views/components/UI/Text'
import { stepThreeSelector, setStepFourTrigger } from 'ducks/createProcess'
import Input from 'views/components/UI/TextInput'
import Steps from 'views/components/Stepper'
import Tooltip from 'views/components/Tooltip'

import { STEPS_NEW_PROCESS } from '../../constants'
import { platformAdminRoutes } from 'router/routes'
import { useTranslation } from 'react-i18next'
import { ModelSkillsProps } from '../skills'

type SkillWithMinimumRecord = {
  id: number
  weight: number
}

type SkillWithMinimumRecordReformated = {
  skill_id: number
  weight: number
}

interface IMinimunRecord {
  skillSelected: any[]
  model3dSkills: ModelSkillsProps[]
  onChange: (skillsWithMinimumRecord: SkillWithMinimumRecordReformated[]) => void
}

const MinimumRecord = (props: IMinimunRecord) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const stepThreeData = useSelector(stepThreeSelector)

  const { skillSelected, onChange, model3dSkills } = props
  const [skillsWithMinimumRecord, setSkillsWithMinimumRecord] = useState<SkillWithMinimumRecord[]>(skillSelected || [])

  useEffect(() => {
    const skillsWithMinScoreReformated = skillsWithMinimumRecord.map((item) => {
      return {
        skill_id: item.id,
        weight: item.weight,
      }
    })
    onChange(skillsWithMinScoreReformated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsWithMinimumRecord])

  const handleSkipStep = () => {
    const skillsWithoutWeight = skillsWithMinimumRecord.map((item) => {
      return {
        skill_id: item.id,
      }
    })

    const dataStepFourToSave: any = {
      processId: stepThreeData?.processId,
      competencesIdealProfile: skillsWithoutWeight,
    }

    dispatch(setStepFourTrigger.run(dataStepFourToSave))
    setTimeout(() => {
      navigate(platformAdminRoutes.CreateUsers)
    }, 500)
  }

  const getMatchingModelNamesTooltip = (
    modelSkills: ModelSkillsProps[],
    skillName: string,
    skillId: number
  ): string => {
    const matchingNames = modelSkills
      .filter((model) => model.skills3d.some((skill3d) => skill3d.id === skillId || skill3d.name === skillName))
      .map((model) => model.name)

    if (matchingNames.length === 0) {
      return 'No se encontraron coincidencias'
    }

    return matchingNames.join(', ')
  }

  return (
    <MinimumRecordContainer>
      <H2>Puntaje mínimo</H2>
      <StepsContainer>
        <Steps steps={STEPS_NEW_PROCESS} activeStep={4} />
      </StepsContainer>
      <Row>
        <Row justify="space-between" margin="24px 0px 0px 0px">
          <H4 margin="30px 0px 24px 0px">{t('global.common.skillText')}</H4>
          <div>
            <Tooltip
              tooltipText="Si decides saltar este paso no podrás utilizar 9Box para este proceso"
              style={{
                marginRight: '-12px',
                transform: 'scale(1.2)',
              }}
            />
            <StrokedButton onClick={handleSkipStep}>Saltar este paso</StrokedButton>
          </div>
        </Row>
      </Row>
      <GridContainer>
        <GridMinimumRecord>
          <GridItem>
            <TitleGrid>Nombre</TitleGrid>
          </GridItem>
          <GridItem>
            <TitleGrid>Porcentaje de puntaje mínimo</TitleGrid>
          </GridItem>
        </GridMinimumRecord>
        <Divider margin="10px 0px 16px 0px" />
      </GridContainer>
      <GridMinimumRecord>
        {skillSelected.map((competence, indexItem) => (
          <React.Fragment key={indexItem}>
            <GridItem>
              <ContainerWithNumbers>
                <P style={{ marginRight: '10px' }}>{indexItem + 1}.</P>
                <P>{competence.skill}</P>
                {model3dSkills.length > 0 && (
                  <Tooltip
                    tooltipText={getMatchingModelNamesTooltip(model3dSkills, competence.skill, competence.id)}
                    placement="right"
                    style={{ fontSize: '14px' }}
                  />
                )}
              </ContainerWithNumbers>
            </GridItem>
            <GridItem height="80px">
              <Input
                type="number"
                name={`minimumRecord-${competence.id}`}
                value={skillsWithMinimumRecord.find((item) => item.id === competence.id)?.weight || '0'}
                onChange={(e) => {
                  if (parseInt(e.target.value) > 100 || parseInt(e.target.value) < 0) {
                    return
                  } else {
                    setSkillsWithMinimumRecord((prev) => {
                      const index = prev.findIndex((item) => item.id === competence.id)
                      if (index === -1) {
                        return [...prev, { id: competence.id, weight: Number(e.target.value) }]
                      }
                      const newSkillsWithMinimumRecord = [...prev]
                      newSkillsWithMinimumRecord[index] = {
                        ...newSkillsWithMinimumRecord[index],
                        weight: Number(e.target.value),
                      }
                      return newSkillsWithMinimumRecord
                    })
                  }
                }}
                label="Porcentaje de puntaje mínimo"
              />
            </GridItem>
          </React.Fragment>
        ))}
      </GridMinimumRecord>
    </MinimumRecordContainer>
  )
}

export default MinimumRecord
