import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

// ACTIONS

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-end;
  padding-left: 40%;

  @media (max-width: 1400px) {
    padding-left: 20%;
  }
  @media (max-width: 1100px) {
    padding-left: 0;
  }
`

export const TextViewMore = styled.p`
  // public sans font
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #c6016b;
  cursor: pointer;
`

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`

export const CheckContainer = styled.div<{ check: boolean; activeColumn?: boolean }>`
  display: flex;
  height: 25px;
  width: 25px;
  margin-left: ${(props) => (props.activeColumn ? '35px' : '60px')};
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: ${(props) => (props.check ? '1px solid #449f0f' : '1px solid #C2C2C2')};
  color: ${(props) => (props.check ? '#449f0f' : '#C2C2C2')};
  background-color: #fff;
`
