import axios from 'lib/axios'

export const getModelWithProcess = async (processId: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/companies/skills/process/${processId}`

  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
