import axios from "axios"

const baseUrl = `${process.env.REACT_APP_MAIN_API}/user/activate`

interface Iprops {
    token: string,
    document: string,
    birthDate: string,
    password: string,
    documentType: number,
}

// service to activate new user
export default async function index (props: Iprops) {

  const { token, document, birthDate, password, documentType} = props
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  }

    const body = {
        "document": document,
        "birth_date": birthDate,
        "password": password,
        "document_type": documentType
    }

    const res = await axios.put(baseUrl, body, { headers }).then((response) => {
        return response.data;
      }).catch((error) => {
        console.error("error active user ",error)
        return { ok: false, message: error.message };
      });
      return res

}
